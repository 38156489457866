import { repository, baseURL } from '@/api/base'
const arraybuffer = { responseType: 'arraybuffer' }

export default {
  async getEmployeesLoad(body) {
    const { data } = await repository.post(`${baseURL}/workload/deptCycleEmplLoad`, body)
    return data.map((e, i, arr) => i + 1 === arr.length ? e : { ...e, shortName: `${e.shortName} (${e.titleCode})` })
  },

  async getDepartmentLoad(body) {
    const { data } = await repository.post(`${baseURL}/workload/deptLoad`, body)
    return data
  },

  async getCyclesLoad(body) {
    const { data } = await repository.post(`${baseURL}/workload/deptCycleLoad`, body)
    return data
  },

  async getDeptLoadXlsx(body) {
    const { data } = await repository.post(`${baseURL}/workload/deptLoadXlsx`, body, arraybuffer)
    return data
  },

  async getPedByFacultyToXls(body) {
    const { data } = await repository.post(`${baseURL}/workload/exportPedByFacultyToXls`, body, arraybuffer)
    return data
  },

  async getPedByDeptToXls(body) {
    const { data } = await repository.post(`${baseURL}/workload/exportPedByDeptToXls`, body, arraybuffer)
    return data
  },

  async getEmplLoadToXls(body) {
    const { data } = await repository.post(`${baseURL}/workload/exportEmplLoadToXls`, body, arraybuffer)
    return data
  }
}