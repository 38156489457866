import {baseURL, repository} from "@/api/base"

export default {
  dpoListenerListForDepartmentNeeds(cycleId) {
    return `${baseURL}/report/dpoListenerListForDepartmentNeeds?cycleId=${cycleId}`
  },

  dpoListenerListForManagementDivision(cycleId) {
    return `${baseURL}/report/dpoListenerListForManagementDivision?cycleId=${cycleId}`
  },

  dpoListenerListForComplectationDivision(cycleId) {
    return `${baseURL}/report/dpoListenerListForComplectationDivision?cycleId=${cycleId}`
  },

  dpoListenerListReceivedCert(cycleId) {
    return `${baseURL}/report/dpoListenerListReceivedCert/${cycleId}`
  },

  dpoScheduleByTeacher(cycleId, emplRateId) {
    return `${baseURL}/report/dpoScheduleByTeacher?cycleId=${cycleId}&emplRateId=${emplRateId}`
  },

  dpoTeacherWorkloadByCycle(cycleId) {
    return `${baseURL}/report/dpoTeacherWorkloadByCycle?cycleId=${cycleId}`
  },

  dpoCycleAttendance(cycleId) {
    return `${baseURL}/report/dpoCycleAttendance?cycleId=${cycleId}`
  },

  dpoListenerListSheetIssueCert(cycleId) {
    return `${baseURL}/report/dpoListenerListSheetIssueCert?cycleId=${cycleId}`
  },

  dpoSchedule(cycleId) {
    return `${baseURL}/report/dpoSchedule?cycleId=${cycleId}`
  },

  dpoCuratorReport(cycleId) {
    return `${baseURL}/report/dpoCuratorReport?cycleId=${cycleId}`
  },

  dpoFinalAttestationProtocol(fileDpoCardId) {
    return `${baseURL}/report/dpoFinalAttestationProtocol?fileDpoCardId=${fileDpoCardId}`
  },

  dpoListenerCard(fileDpoCardId) {
    return `${baseURL}/report/dpoListenerCard?fileDpoCardId=${fileDpoCardId}`
  },

  dpoListenerCardByCycle(cycleId) {
    return `${baseURL}/report/dpoListenerCardByCycle?cycleId=${cycleId}`
  },

  dpoListenerCardCommon() {
    return `${baseURL}/report/dpoListenerCard`
  },

  dpoListenerContract(cycleId) {
    return `${baseURL}/report/dpoListenerContract?cycleId=${cycleId}`
  },

  dpoListenerContractByCycle(cycleId) {
    return `${baseURL}/report/dpoListenerContractByCycle?cycleId=${cycleId}`
  },

  dpoListenerContractForListOfListeners(list) {
    const queryParams = list.reduce((prev, current, i) => prev + `${i === 0 ? '?' : '&'}fileDpoCardId=${current}`, '')
    return `${baseURL}/report/dpoListenerContract${queryParams}`
  },

  dpoListenerContractNMO(cycleId) {
    return `${baseURL}/report/dpoListenerContractNMO?cycleId=${cycleId}`
  },

  dpoListenerContractNMOByCycle(cycleId) {
    return `${baseURL}/report/dpoListenerContractNMOByCycle?cycleId=${cycleId}`
  },

  dpoExamProtocol(cycleId) {
    return `${baseURL}/report/dpoExamProtocol?cycleId=${cycleId}`
  },

  dpoListenerContractNMOForListOfListeners(list) {
    const queryParams = list.reduce((prev, current, i) => prev + `${i === 0 ? '?' : '&'}fileDpoCardId=${current}`, '')
    return `${baseURL}/report/dpoListenerContractNMO${queryParams}`
  },

  async getDpoListenerListForMoodleExcel(cycleId) {
    const { data } = await repository.get(`${baseURL}/report/dpoListenerListForMoodle/${cycleId}`, {
      responseType: "arraybuffer"
    })

    return data
  },

  async getScheduleTemplateExcel() {
    const { data } = await repository.get(`${baseURL}/report/getReportTemplate`, { responseType: "arraybuffer" })
    return data
  },

  async getCuratorDepartmentReport(body) {
    const { data } = await repository.post(`${baseURL}/listenersCount/curator`, body)
    return data
  },

  async getCuratorFacultyReport(body) {
    const { data } = await repository.post(`${baseURL}/listenersCount/faculty`, body)
    return data
  },

  async getCuratorReportToXls(body) {
    const { data } = await repository.post(`${baseURL}/listenersCount/exportByEmplToXls`, body, {
      responseType: "arraybuffer"
    })

    return data
  },

  async getTeacherDepartmentReport(body) {
    const { data } = await repository.post(`${baseURL}/workload/exportPedByDeptToXls`, body, {
      responseType: "arraybuffer"
    })

    return data
  },

  async getTeacherFacultyReport(body) {
    const { data } = await repository.post(`${baseURL}/workload/exportPedByFacultyToXls`, body, {
      responseType: "arraybuffer"
    })

    return data
  },

  getReportParentDeptListenerCount(params) {
    const queryParams = `?departmentId=${params.departmentId}&fromDate=${params.fromDate}&toDate=${params.toDate}`
    return `${baseURL}/report/getReportParentDeptListenerCount${queryParams}`
  },

  getReportCuratorListenerCount(params) {
    const queryParams = `?departmentId=${params.departmentId}&fromDate=${params.fromDate}&toDate=${params.toDate}`
    return `${baseURL}/report/getReportCuratorListenerCount${queryParams}`
  },

  async uploadScheduleTemplate(departmentId, body) {
    const { data } = await repository.post(`${baseURL}/report/schtemp/${departmentId}`, body)
    return data
  }
}