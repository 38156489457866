const sortDepartmentsBySecondWord = (a, b) => {
  const aWithoutFirstWord = a.name.split(' ').slice(1).join(' ').trim()
  const bWithoutFirstWord = b.name.split(' ').slice(1).join(' ').trim()

  if (aWithoutFirstWord > bWithoutFirstWord) return 1
  if (aWithoutFirstWord < bWithoutFirstWord) return -1
  return 0
}

import { repository, baseURL } from "@/api/base"

export default {
  async getDepartmentsList() {
    const { data } = await repository.get(`${baseURL}/dictionary/department`)
    return data.sort(sortDepartmentsBySecondWord)
  },

  async getDepartmentById(departmentId) {
    const { data } = await repository.get(`${baseURL}/dictionary/${departmentId}/department`)
    return data
  },

  async getFacultiesList() {
    const { data } = await repository.get(`${baseURL}/department/getFaculties`)
    return data
  },

  async getCyclesByDepartmentId(departmentId) {
    const { data } = await repository.get(`${baseURL}/department/${departmentId}/getCycles`)
    return data
  },

  async getCyclesAllTimeByDepartmentId(departmentId) {
    const { data } = await repository.get(`${baseURL}/department/${departmentId}/getCyclesAllTime`)
    return data
  },

  async getCyclesByYear(year) {
    const { data } = await repository.get(`${baseURL}/department/getCyclesByYear?acadamyYearId=${year}`)
    return data
  },

  async getEmployeesListByDepartmentId(departmentId) {
    const { data } = await repository.get(`${baseURL}/department/${departmentId}/emplByDept`)
    return data.sort((a, b) => a.fullName.localeCompare(b.fullName))
  },

  async setDepartmentHeadTeacher(departmentId, body) {
    const { data } = await repository.post(`${baseURL}/department/${departmentId}/setHeadTeacher`, body)
    return data
  }
}