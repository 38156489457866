import { vm } from '@/main'
import { authApi } from '@/api'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    userId: null,
    username: null,
    roles: [],

    remainedTime: null,
    remainedTimeTimerId: null,
    remainedTimeMessage: null
  },

  getters: {
    userId: state => state.userId,
    username: state => state.username,
    allUserRoles: state => state.roles,
    remainedTimeMessage: state => state.remainedTimeMessage,

    isOperatorRoles: state => {
      const operatorRoles = [
        'ROLE_SUPERADMIN',
        'ROLE_ADMIN',
        'ROLE_AUDITOR',
        'ROLE_CURATOR',
        'ROLE_DEAN',
        'ROLE_DEPARTMENT_HEAD',
        'ROLE_EMPLOYEE',
        'ROLE_HEAD_TEACHER',
        'ROLE_TRAINING_MANAGER'
      ]

      return state.roles.some(role => operatorRoles.includes(role))
    },

    isSuperAdmin: state => state.roles.some(role => role === 'ROLE_SUPERADMIN'),
    isAdmin: state => state.roles.some(role => role === 'ROLE_ADMIN'),
    isAuditor: state => state.roles.some(role => role === 'ROLE_AUDITOR'),
    isCurator: state => state.roles.some(role => role === 'ROLE_CURATOR'),
    isDean: state => state.roles.some(role => role === 'ROLE_DEAN'),
    isDepartmentHead: state => state.roles.some(role => role === 'ROLE_DEPARTMENT_HEAD'),
    isEmployee: state => state.roles.some(role => role === 'ROLE_EMPLOYEE'),
    isHeadTeacher: state => state.roles.some(role => role === 'ROLE_HEAD_TEACHER'),
    isTrainingManager: state => state.roles.some(role => role === 'ROLE_TRAINING_MANAGER')
  },

  mutations: {
    setUserParams(state, payload) {
      state.userId = payload.id
      state.username = payload.username
      state.roles = payload.authorities.map(role => role.authority)
      state.remainedTime = payload.remainedTime
      // state.roles = ['ROLE_AUDITOR']
      // state.roles = []
    },

    setRemainedTime(state, time) {
      state.remainedTime = time
    },

    setRemainedTimeTimerId(state, timerId) {
      state.remainedTimeTimerId = timerId
    },

    setRemainedTimeMessage(state, message) {
      state.remainedTimeMessage = message
    },

    clearUserParams(state) {
      state.userId = null
      state.username = null
      state.roles = []
      state.remainedTime = null

      clearInterval(state.remainedTimeTimerId)
      state.remainedTimeTimerId = null
      state.remainedTimeMessage = null
    }
  },

  actions: {
    async login({ commit, dispatch }, payload) {
      const formData = new FormData();
      formData.append('username', payload.username)
      formData.append('password', payload.password)

      const data = await authApi.login(formData)
      // вызываем getCurrent, чтобы получить remainedTime
      await dispatch('getCurrent')

      return data
    },

    async getCurrent({ state, commit, dispatch }) {
      if (!(state.userId && state.username && state.roles.length)) {
        const data = await authApi.getCurrent()
        commit('setUserParams', data)

        const timerId = setInterval(() => dispatch('setRemainedTimeParams'), 1000)
        commit('setRemainedTimeTimerId', timerId)
      }
    },

    async logout({ commit }) {
      await authApi.logout()
      commit('clearUserParams')
      commit('department/clearDepartment', null, { root: true })
    },

    async setRemainedTimeParams({ state, commit, dispatch }) {
      if (state.remainedTime === null || state.remainedTime < 0) {
        await dispatch('logout')
        await router.replace({ path: '/login' })
        vm.$toastr('warning', '', 'Вы не авторизованы или время сессии истекло')
      } else {
        const thirtyMinutes = 1000 * 60 * 30

        if (state.remainedTime <= thirtyMinutes) {
          let minutesLeft = Math.floor(state.remainedTime / 1000 / 60)
          let secondsLeft = Math.floor(state.remainedTime / 1000) - minutesLeft * 60

          if (minutesLeft < 10) minutesLeft = `0${minutesLeft}`
          if (secondsLeft < 10) secondsLeft = `0${secondsLeft}`

          const message = `До окончания текущей авторизации осталось ${minutesLeft}:${secondsLeft}. 
            Рекомендуется сохранить данные.`
          commit('setRemainedTimeMessage', message)
        } else {
          commit('setRemainedTimeMessage', null)
        }

        commit('setRemainedTime', state.remainedTime - 1000)
      }
    }
  }
}