import { repository, baseURL } from '@/api/base'

export default {
  async getLecturersListByCycleId(cycleId) {
    const { data } = await repository.get(`${baseURL}/schedule/${cycleId}/getLecturers`)
    return data
  },


  // блок роутов страницы "Время занятий"
  async getLessonsBeginList(departmentId) {
    const { data } = await repository.get(`${baseURL}/schedule/beginTimeValues/lesson/${departmentId}`)
    return data.sort((a, b) => a.time.localeCompare(b.time))
  },

  async getBreaksDurationList(departmentId) {
    const { data } = await repository.get(`${baseURL}/schedule/durationValues/break/${departmentId}`)
    return data.sort((a, b) => a.minutes - b.minutes)
  },

  async getBigbreaksBeginList(departmentId) {
    const { data } = await repository.get(`${baseURL}/schedule/beginTimeValues/bigbreak/${departmentId}`)
    return data.sort((a, b) => a.time.localeCompare(b.time))
  },

  async getBigbreaksDurationList(departmentId) {
    const { data } = await repository.get(`${baseURL}/schedule/durationValues/bigbreakmin/${departmentId}`)
    return data.sort((a, b) => a.minutes - b.minutes)
  },

  async sendBeginTime(body) {
    const { data } = await repository.post(`${baseURL}/schedule/beginTime`, body)
    return data
  },

  async sendDuration(body) {
    const { data } = await repository.post(`${baseURL}/schedule/duration`, body)
    return data
  },

  async removeBeginTime(id) {
    const { data } = await repository.delete(`${baseURL}/schedule/beginTime/${id}`)
    return data
  },

  async removeDuration(id) {
    const { data } = await repository.delete(`${baseURL}/schedule/duration/${id}`)
    return data
  },
  // конец блока роутов страницы "Время занятий"


  async generateSchedule(scheduleTemplateId, cycleId, body) {
    const { data } = await repository.post(`${baseURL}/schedule/${scheduleTemplateId}/${cycleId}/generateSchedule`, body)
    return data
  },

  async getUndistributedHoursPlan(cycleId) {
    const { data } = await repository.get(`${baseURL}/schedule/${cycleId}/getUndistributedHoursPlan`)
    return data
  },

  async getUndistributedHoursFact(cycleId) {
    const { data } = await repository.get(`${baseURL}/schedule/${cycleId}/getUndistributedHoursFact`)
    return data
  },

  async getChapters(cycleId) {
    const { data } = await repository.get(`${baseURL}/schedule/${cycleId}/chapters`)
    return data
  },

  async getPlanSchedule(cycleId) {
    const { data } = await repository.get(`${baseURL}/schedule/${cycleId}/planSchedule`)
    return data
  },

  async getFactSchedule(cycleId) {
    const { data } = await repository.get(`${baseURL}/schedule/${cycleId}/factSchedule`)
    return data
  },

  async sendPlanSchedule(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/schedule/${cycleId}/planSchedule`, body)
    return data
  },

  async sendFactSchedule(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/schedule/${cycleId}/factSchedule`, body)
    return data
  },

  async removePlanSchedule(cycleId) {
    return await repository.delete(`${baseURL}/schedule/${cycleId}/planSchedule`)
  },

  async removeFactSchedule(cycleId) {
    return await repository.delete(`${baseURL}/schedule/${cycleId}/factSchedule`)
  },


  async getScheduleTemplatesList(departmentId) {
    const { data } = await repository.get(`${baseURL}/schedule/template/${departmentId}`)
    return data
  },

  async getScheduleTemplateById(scheduleTemplateId) {
    const { data } = await repository.get(`${baseURL}/schedule/${scheduleTemplateId}/getTemplateById`)
    return data
  },

  async createScheduleTemplate(departmentId, body) {
    const { data } = await repository.post(`${baseURL}/schedule/template/${departmentId}`, body)
    return data
  },

  async renameScheduleTemplate(id, body) {
    const { data } = await repository.put(`${baseURL}/schedule/${id}/renameTemplate`, body)
    return data
  },

  async saveScheduleTemplate(departmentId, body) {
    const { data } = await repository.put(`${baseURL}/schedule/template/${departmentId}`, body)
    return data
  },

  async copyScheduleTemplate(id) {
    const { data } = await repository.get(`${baseURL}/schedule/${id}/copyTemplate`)
    return data
  },

  async removeScheduleTemplate(scheduleTemplateId) {
    return await repository.delete(`${baseURL}/schedule/${scheduleTemplateId}/removeTemplate`)
  }
}