import { cycleApi } from '@/api'
import { Cycle } from '@/models'
import { formatDateToISO } from '@/scripts'

export default {
  namespaced: true,

  state: {
    cycle: null
  },

  getters: {
    cycleInfo: state => state.cycle,
    cycleId: state => state.cycle.cycleId,
    cycleNum: state => state.cycle.cycleNum,
    isCycleCanceled: state => state.cycle.state?.id === 4,
    isCycleComplete: state => state.cycle.state?.id === 3,
    fromDate: state => state.cycle.fromDate,
    toDate: state => state.cycle.toDate,
    isSchedule: state => state.cycle.isSchedule,
    groupCnt: state => state.cycle.groupCnt,
    groupCntFact: state => state.cycle.groupCntFact,
    planDist: state => state.cycle.planDist,
    factDist: state => state.cycle.factDist,

    currentDateMoreCycleFromDate: state => {
      const currentDateISO = formatDateToISO(new Date())
      return state.cycle.fromDate && new Date(currentDateISO) > new Date(state.cycle.fromDate)
    }
  },

  mutations: {
    setCycle(state, payload) {
      state.cycle = payload
    },

    setIsSchedule(state, payload) {
      state.cycle.setIsSchedule(payload)
    }
  },

  actions: {
    async setCycle({ commit }, cycleId) {
      try {
        const data = await cycleApi.getCycle(cycleId)
        commit('setCycle', Cycle.buildFromAPI(data))
      } catch (e) {
        console.log(e.status)
      }
    },

    setIsSchedule({ commit }, val) {
      commit('setIsSchedule', val)
    }
  }
}