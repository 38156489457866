export class Department {
  constructor() {
    this.axAddrRoute = null
    this.axAddress = null
    this.axClosed = null
    this.axCompanyGroup = null
    this.axDeptNum = null
    this.axPhone = null
    this.closed = null
    this.code = null
    this.dateFrom = null
    this.dateTo = null
    this.id = null
    this.managerId = null
    this.managerName = null
    this.name = null
  }

  getForAPI() {
    return {
      axAddrRoute: this.axAddrRoute,
      axAddress: this.axAddress,
      axClosed: this.axClosed,
      axCompanyGroup: this.axCompanyGroup,
      axDeptNum: this.axDeptNum,
      axPhone: this.axPhone,
      closed: this.closed,
      code: this.code,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      id: this.id,
      managerId: this.managerId,
      managerName: this.managerName,
      name: this.name
    }
  }

  static buildFromAPI(obj) {
    const _this = new Department()

    if (obj) {
      _this.axAddrRoute = obj.axAddrRoute
      _this.axAddress = obj.axAddress
      _this.axClosed = obj.axClosed
      _this.axCompanyGroup = obj.axCompanyGroup
      _this.axDeptNum = obj.axDeptNum
      _this.axPhone = obj.axPhone
      _this.closed = obj.closed
      _this.code = obj.code
      _this.dateFrom = obj.dateFrom
      _this.dateTo = obj.dateTo
      _this.id = obj.id
      _this.managerId = obj.managerId
      _this.managerName = obj.managerName
      _this.name = obj.name
    }

    return _this
  }
}