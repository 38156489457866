export class Education {
	constructor() {
		this._eduLevel = null
		this.eduSchool = null
		this.eduSchoolOutList = null
		this.eduYear = null

		this.eduCardType = null
		this.eduCardSeries = null
		this.eduCardNumber = null
		this.eduFirstSpecCenter = null
		this.eduFirstSpecCenterOutList = null
		this.eduCardIssueDate = null

		this.eduSpecialtySpc = null
		this.eduSpecialtyInt = null
		this.eduSpecialtyOrd = null
		this.eduSpecialtyAsp = null
		this.eduSpecialtyDpo = null

		this.eduAcademicDegree = null
		this.eduAcademicDocNumber = null
		this.eduAcademicDocDate = null
		this.eduAcademicCategory = null
		this.eduQualificationCategory = null

		this.indNumOfHealthWorker = null
		this.dateInclude2Register = null

		this._isValidAccred = false
		this.validAccredGosNum = null
		this.validAccredRegNum = null
		this.specialityTable = null

		// служебные
		this._eduSchoolCheck = null
		this._eduFirstSpecCenterCheck = null
	}

  get isDiploma() {
    return this.eduCardType?.id === 1
  }

	get eduLevel() {
		return this._eduLevel
	}
	set eduLevel(val) {
		this._eduLevel = val
		this.eduSchool = null
		this.eduSchoolOutList = null
		this.eduYear = null
	}

	get isValidAccred() {
		return this._isValidAccred
	}

	set isValidAccred(val) {
		this._isValidAccred = val
		this.validAccredGosNum = null
		this.validAccredRegNum = null
		this.specialityTable = null
	}

	get eduSchoolCheck() {
		return this._eduSchoolCheck
	}
	set eduSchoolCheck(val) {
		this._eduSchoolCheck = val
		val ? this.eduSchool = null : this.eduSchoolOutList = null
	}

	get eduFirstSpecCenterCheck() {
		return this._eduFirstSpecCenterCheck
	}
	set eduFirstSpecCenterCheck(val) {
		this._eduFirstSpecCenterCheck= val
		val ? this.eduFirstSpecCenter = null : this.eduFirstSpecCenterOutList = null
	}

	copyEduSchoolInfo(val) {
		if (val) {
			this.eduFirstSpecCenter = this.eduSchool
			this.eduFirstSpecCenterOutList = this.eduSchoolOutList
			this.eduFirstSpecCenterCheck = this.eduSchoolCheck
		}
	}

	getForApi() {
		return {
			eduLevel: this.eduLevel,
			eduSchool: this.eduSchool,
			eduSchoolOutList: this.eduSchoolOutList,
			eduYear: this.eduYear,

			eduCardType: this.eduCardType,
			eduCardSeries: this.eduCardSeries,
			eduCardNumber: this.eduCardNumber,
			eduFirstSpecCenter: this.eduFirstSpecCenter,
			eduFirstSpecCenterOutList: this.eduFirstSpecCenterOutList,
			eduCardIssueDate: this.eduCardIssueDate,

			eduSpecialtySpc: this.eduSpecialtySpc,
			eduSpecialtyInt: this.eduSpecialtyInt,
			eduSpecialtyOrd: this.eduSpecialtyOrd,
			eduSpecialtyAsp: this.eduSpecialtyAsp,
			eduSpecialtyDpo: this.eduSpecialtyDpo,

			eduAcademicDegree: this.eduAcademicDegree,
			eduAcademicDocNumber: this.eduAcademicDocNumber,
			eduAcademicDocDate: this.eduAcademicDocDate,
			eduAcademicCategory: this.eduAcademicCategory,
			eduQualificationCategory: this.eduQualificationCategory,

			indNumOfHealthWorker: this.indNumOfHealthWorker,
			dateInclude2Register: this.dateInclude2Register,

			isValidAccred: this.isValidAccred,
			validAccredGosNum: this.validAccredGosNum,
			validAccredRegNum: this.validAccredRegNum,
			specialityTable: this.specialityTable
		}
	}

  static buildFromAPI(obj) {
    const _this = new Education()

		if (obj) {
			_this.eduLevel = obj.eduLevel
			_this.eduSchool = obj.eduSchool
			_this.eduSchoolOutList = obj.eduSchoolOutList
			_this.eduSchoolCheck = !!_this.eduSchoolOutList
			_this.eduYear = obj.eduYear

			_this.eduCardType = obj.eduCardType
			_this.eduCardSeries = obj.eduCardSeries
			_this.eduCardNumber = obj.eduCardNumber
			_this.eduFirstSpecCenter = obj.eduFirstSpecCenter
			_this.eduFirstSpecCenterOutList = obj.eduFirstSpecCenterOutList
			_this.eduFirstSpecCenterCheck = !!_this.eduFirstSpecCenterOutList
			_this.eduCardIssueDate = obj.eduCardIssueDate

			_this.eduSpecialtySpc = obj.eduSpecialtySpc
			_this.eduSpecialtyInt = obj.eduSpecialtyInt
			_this.eduSpecialtyOrd = obj.eduSpecialtyOrd
			_this.eduSpecialtyAsp = obj.eduSpecialtyAsp
			_this.eduSpecialtyDpo = obj.eduSpecialtyDpo

			_this.eduAcademicDegree = obj.eduAcademicDegree
			_this.eduAcademicDocNumber = obj.eduAcademicDocNumber
			_this.eduAcademicDocDate = obj.eduAcademicDocDate
			_this.eduAcademicCategory = obj.eduAcademicCategory
			_this.eduQualificationCategory = obj.eduQualificationCategory

			_this.indNumOfHealthWorker = obj.indNumOfHealthWorker
			_this.dateInclude2Register = obj.dateInclude2Register

			_this._isValidAccred = obj.isValidAccred || false
			_this.validAccredGosNum = obj.validAccredGosNum
			_this.validAccredRegNum = obj.validAccredRegNum
			_this.specialityTable = obj.specialityTable
		}

		return _this
	}
}