export default {
  namespaced: true,

  state: {
    windowInnerWidth: null
  },

  getters: {
    isSmAndDown: state => state.windowInnerWidth < 960,
    isXs: state => state.windowInnerWidth < 620,
    isMobile: state => state.windowInnerWidth < 520
  },

  mutations: {
    setWindowInnerWidth(state, payload) {
      state.windowInnerWidth = payload
    }
  },

  actions: {
    addWindowWidthEventListener({ commit }) {
      commit('setWindowInnerWidth', window.innerWidth)
      window.addEventListener("resize", () => commit('setWindowInnerWidth', window.innerWidth))
    },

    removeWindowWidthEventListener({ commit }) {
      commit('setWindowInnerWidth', null)
      window.removeEventListener("resize", () => commit('setWindowInnerWidth', window.innerWidth))
    }
  }
}