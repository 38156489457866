import { repository, baseURL } from "@/api/base"

export default {
  async getThemes(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/getThemes`)
    return data
  },

  async addTheme(body) {
    const { data } = await repository.post(`${baseURL}/cycle/addTheme`, body)
    return data
  },

  async updateTheme(body) {
    const { data } = await repository.put(`${baseURL}/cycle/updateTheme`, body)
    return data
  },

  async removeTheme(cycleId, id) {
    return await repository.delete(`${baseURL}/cycle/${cycleId}/deleteTheme/${id}`)
  }
}