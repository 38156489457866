import { repository, cancelToken, baseURL } from '@/api/base'
let cancel = null

export default {
  async getCycles(departmentId, pageNumber, body) {
    cancel && cancel()

    const { data } = await repository.post(`${baseURL}/cycle/cycleList/${departmentId}/${pageNumber}`, body, {
      cancelToken: new cancelToken(c => cancel = c)
    })

    return data
  },

  async getCycle(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/getHead`)
    return data
  },

  async getCycleInfo(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}`)
    return data
  },

  async addThemeList(cycleId) {
    return await repository.post(`${baseURL}/cycle/addThemeList/` + cycleId)
  },

  async getCycleAttributes(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/attribute`)
    return data
  },

  async sendCycleAttributes(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/cycle/${cycleId}/attribute`, body)
    return data
  },

  async getHeadTeacher(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/getHeadTeacher`)
    return data
  },

  async setHeadTeacher(cycleId, body) {
    return await repository.post(`${baseURL}/cycle/${cycleId}/setHeadTeacher`, body)
  },

  async deleteHeadTeacher(cycleId) {
    return await repository.delete(`${baseURL}/cycle/${cycleId}/deleteHeadTeacher`)
  },

  async getCurator(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/getCurator`)
    return data
  },

  async setCurator(cycleId, body) {
    return await repository.post(`${baseURL}/cycle/${cycleId}/setCurator`, body)
  },

  async deleteCurator(cycleId) {
    return await repository.delete(`${baseURL}/cycle/${cycleId}/deleteCurator`)
  },

  async getEmail(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/getEmail`)
    return data
  },

  async setEmail(cycleId, body) {
    return await repository.post(`${baseURL}/cycle/${cycleId}/setEmail`, body)
  },

  async getEmployeesListByCycleId(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/emplByDeptPeriod/${cycleId}`)
    return data.sort((a, b) => a.lastName.localeCompare(b.lastName))
  },

  async getListenersByCycleId(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/getDpoCardValid_`)
    return data.sort((a, b) => a.fullName.localeCompare(b.fullName))
  },

  async getListenersTabEditability(cycleId) {
    const { data } = await repository.get(`${baseURL}/cycle/${cycleId}/tabEditability`)
    return data
  }
}