import { repository, baseURL } from "@/api/base"

export default {
  async getCycleHolidays(cycleId) {
    const { data } = await repository.get(`${baseURL}/schedule/${cycleId}/holidays`)
    return data
  },

  async sendCycleHolidays(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/schedule/${cycleId}/holidays`, body)
    return data
  },


  async getHolidays() {
    const { data } = await repository.get(`${baseURL}/schedule/allHoliday/byCurrentYear`)
    return data
  },

  async sendHoliday(body) {
    const { data } = await repository.post(`${baseURL}/schedule/allHoliday`, body)
    return data
  },

  async removeHoliday(id) {
    return await repository.delete(`${baseURL}/schedule/allHoliday/${id}`)
  }
}