import { repository, baseURL } from "@/api/base"

export default {
  async getCountMembers(cycleId) {
    const { data } = await repository.get(`${baseURL}/exam/${cycleId}/getCountMembers`)
    return data.total
  },

  async getChairman(cycleId) {
    const { data } = await repository.get(`${baseURL}/exam/${cycleId}/getChairman`)
    return data
  },

  async setChairman(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/exam/${cycleId}/setChairman`, body)
    return data
  },

  async getSecretary(cycleId) {
    const { data } = await repository.get(`${baseURL}/exam/${cycleId}/getSecretary`)
    return data
  },

  async setSecretary(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/exam/${cycleId}/setSecretary`, body)
    return data
  },

  async getMembers(cycleId) {
    const { data } = await repository.get(`${baseURL}/exam/${cycleId}/getMembers`)
    return data
  },

  async setMember(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/exam/${cycleId}/setMember`, body)
    return data
  },

  async removeStaff(cycleStaffId) {
    const { data } = await repository.delete(`${baseURL}/exam/remove/${cycleStaffId}`)
    return data
  }
}