import { Department } from '@/models/department'

export class ChapterList {
  constructor() {
    this.list = []
  }

  addChapter(chapter) {
    !this.checkInList(chapter) && this.list.push(Chapter.buildFromAPI(chapter))
  }

  checkInList(item) {
    return this.list.some(el => el.id === item.id)
  }

  updateChapter(chapter) {
    const index = this.list.findIndex(el => el.id === chapter.id)
    this.list.splice(index, 1, Chapter.buildFromAPI(chapter))
  }

  removeChapter(id) {
    const index = this.list.findIndex(el => el.id === id)
    this.list.splice(index, 1)
  }

  getForAPI() {
    return this.list.map(chapter => chapter.getForAPI())
  }

  buildFromAPI(obj) {
    if (obj) {
      this.list = obj.map(chapter => Chapter.buildFromAPI(chapter))
    }

    return this
  }

  clearAll() {
    this.list = []
  }
}


export class Chapter {
  constructor() {
    this.id = null
    this.name = null
    this.department = null
  }

  getForAPI() {
    return {
      id: this.id,
      name: this.name,
      department: this.department ? this.department.getForAPI() : null
    }
  }

  static buildFromAPI(obj) {
    const _this = new Chapter()

    if (obj) {
      _this.id = obj.id
      _this.name = obj.name
      _this.department = obj.department ? Department.buildFromAPI(obj.department) : null
    }

    return _this
  }
}