import { formatDateReverseToISO, formatDateReverseFromISO } from '@/scripts'

export class CycleAccess {
  constructor() {
    this.list = []
  }

  updateCycleAccessLine(line) {
    const index = this.list.findIndex(el => el.cycleAccessParamId === line.cycleAccessParamId)
    this.list.splice(index, 1, line)
  }

  getForAPI() {
    return this.list.map(el => el.getForAPI())
  }

  buildFromAPI(obj) {
    if (obj) {
      this.list = obj.map(el => CycleAccessLine.buildFromAPI(el))
    }

    return this
  }
}


export class CycleAccessLine {
  constructor() {
    this.cycleAccessParamId = null
    this.cycleAccessParamType = null
    this.before = false // действие правила: до даты / после даты
    this.begin = false // относительно дат цикла: начало / завершение
    this.enableTo = null
    this.days = null

    // служебное
    this._dateModeAbsolute = false
  }

  get dateModeAbsolute() {
    return this._dateModeAbsolute
  }

  set dateModeAbsolute(val) {
    this._dateModeAbsolute = val
    this.enableTo = null
    this.days = null
  }

  get cycleDates() {
    return this.dateModeAbsolute ? '-' : (this.begin ? 'Начало' : 'Завершение')
  }

  get daysValue() {
    return this.dateModeAbsolute ? this.setDateFormat(this.enableTo) : this.days
  }

  setIsoDateFormat(date) {
    return /(\d{2}).(\d{2}).(\d{4})/.test(date)
      ? formatDateReverseToISO(date)
      : null
  }

  setDateFormat(date) {
    return /(\d{4})-(\d{2})-(\d{2})/.test(date)
      ? formatDateReverseFromISO(date)
      : null
  }

  getForAPI() {
    return {
      cycleAccessParamId: this.cycleAccessParamId,
      cycleAccessParamType: this.cycleAccessParamType,
      before: this.before,
      begin: this.begin,
      enableTo: this.setDateFormat(this.enableTo),
      days: this.days
    }
  }

  static buildFromAPI(obj) {
    const _this = new CycleAccessLine()

    if (obj) {
      _this.cycleAccessParamId = obj.cycleAccessParamId
      _this.cycleAccessParamType = obj.cycleAccessParamType
      _this.before = obj.before
      _this.begin = obj.begin
      _this.enableTo = _this.setIsoDateFormat(obj.enableTo)
      _this.days = obj.days

      _this._dateModeAbsolute = !!obj.enableTo
    }

    return _this
  }
}