import { vm } from '@/main'
import _cloneDeep from 'lodash/cloneDeep'

export class DraggableTable {
  constructor() {
    this.tableContainer = null
    this.table = null
    this.tbody = null

    this.currRow = null
    this.dragElem = null
    this.mouseDownX = 0
    this.mouseDownY = 0
    this.mouseX = 0
    this.mouseY = 0
    this.intervalLoop = 0
    this.mouseDrag = false

    this.scheduleTemplate = null
    this.currLine = null
  }

  setTable(tableLink) {
    this.tableContainer = tableLink
    const table = tableLink.querySelector('table')

    if (table) {
      this.table = table
      this.tbody = this.table.querySelector('tbody')
    }
  }

  setScheduleTemplate(data) {
    this.scheduleTemplate = data
  }

  init() {
    this.bindMouse()
  }

  bindMouse() {
    document.addEventListener('mousedown', event => {
      if (event.button !== 0) return true

      const target = this.getTargetRow(event.target)

      if (target) {
        this.currRow = target
        this.currLine = this.getLine(target)

        this.addDraggableRow(target)
        this.currRow.classList.add('is-dragging')

        const coords = this.getMouseCoords(event)
        this.mouseDownX = coords.x
        this.mouseDownY = coords.y

        this.mouseDrag = true
      }
    })

    document.addEventListener('mousemove', event => {
      if (!this.mouseDrag) return

      this.scrollTable(event)

      const coords = this.getMouseCoords(event)
      this.mouseX = coords.x - this.mouseDownX
      this.mouseY = coords.y - this.mouseDownY

      this.moveRow(this.mouseX, this.mouseY)
    })

    document.addEventListener('mouseup', event => {
      if (!this.mouseDrag) return

      if (this.scheduleTemplate.isSingleLineOnDay(this.currLine)) {
        // если перетягиваем строку, которая единственная на дню
        vm.$toastr('error', '', 'Дни занятий должны идти подряд')
        this.scheduleTemplate.reRenderTable()
      } else {
        const targetPositionInfo = this.getRowTargetPositionInfo(event)

        if (!targetPositionInfo) {
          console.log('Ошибка перемещения строки шаблона')
        } else {
          const cloneEditLine = _cloneDeep(this.currLine)
          cloneEditLine.dayNumber = targetPositionInfo.dayNumber

          this.scheduleTemplate.dragEditLine(cloneEditLine, targetPositionInfo.rowOrder, targetPositionInfo.setUnderRow)
          this.scheduleTemplate.hasAnyChanges = true
        }

        this.scheduleTemplate.reRenderTable()
      }

      clearInterval(this.intervalLoop)
      this.intervalLoop = 0

      this.currRow.classList.remove('is-dragging')
      this.table.removeChild(this.dragElem)

      this.dragElem = null
      this.mouseDrag = false
    })
  }

  scrollTable(event) {
    const vueThis = this
    const tableContainerPosition = this.tableContainer.getBoundingClientRect()

    if (event.pageY - 150 < tableContainerPosition.top) {
      clearInterval(this.intervalLoop)
      this.intervalLoop = setInterval(function() {
        vueThis.tableContainer.scrollTop = vueThis.tableContainer.scrollTop - 2
      }, 25)
    } else if (event.pageY + 50 > tableContainerPosition.bottom) {
      clearInterval(this.intervalLoop)
      this.intervalLoop = setInterval(function() {
        vueThis.tableContainer.scrollTop = vueThis.tableContainer.scrollTop + 2
      }, 25)
    }
  }

  swapRow(row, index) {
    const currIndex = Array.from(this.tbody.children).indexOf(this.currRow),
      row1 = currIndex > index ? this.currRow : row,
      row2 = currIndex > index ? row : this.currRow

    this.tbody.insertBefore(row1, row2)
  }

  moveRow(x, y) {
    this.dragElem.style.transform = `translate3d(${x}px, ${y}px, 0)`

    const	dPos = this.dragElem.getBoundingClientRect(),
      currStartY = dPos.y,
      currEndY = currStartY + dPos.height,
      rows = this.getRows()

    for (let i = 0; i < rows.length; i++) {
      const rowElem = rows[i],
        rowSize = rowElem.getBoundingClientRect(),
        rowStartY = rowSize.y, rowEndY = rowStartY + rowSize.height

      if (this.currRow !== rowElem && this.isIntersecting(currStartY, currEndY, rowStartY, rowEndY)) {
        if (Math.abs(currStartY - rowStartY) < rowSize.height / 2)
          this.swapRow(rowElem, i)
      }
    }
  }

  addDraggableRow(target) {
    this.dragElem = target.cloneNode(true)
    this.dragElem.classList.add('draggable-table__drag')
    this.dragElem.style.height = this.getStyle(target, 'height')
    this.dragElem.style.background = this.getStyle(target, 'backgroundColor')

    for (let i = 0; i < target.children.length; i++) {
      const oldTD = target.children[i],
        newTD = this.dragElem.children[i]

      newTD.style.width = this.getStyle(oldTD, 'width')
      newTD.style.height = this.getStyle(oldTD, 'height')
      newTD.style.padding = this.getStyle(oldTD, 'padding')
      newTD.style.margin = this.getStyle(oldTD, 'margin')
    }

    this.table.appendChild(this.dragElem)

    this.dragElem.style.bottom = 0
    this.dragElem.style.top = this.getPosition(target) + 'px'
    this.dragElem.style.left = '-1px'

    document.dispatchEvent(new MouseEvent('mousemove',
      { view: window, cancelable: true, bubbles: true }
    ))
  }

  getRows() {
    return this.table.querySelectorAll('tbody tr')
  }

  getTargetRow(target) {
    const elemName = target.tagName.toLowerCase()

    if (elemName === 'tr') return target
    if (elemName === 'td') return target.closest('tr')
  }

  getMouseCoords(event) {
    return {
      x: event.clientX,
      y: event.clientY
    }
  }

  getStyle(target, styleName) {
    const compStyle = getComputedStyle(target)
    return compStyle[styleName] || null
  }

  isIntersecting(min0, max0, min1, max1) {
    return Math.max(min0, max0) >= Math.min(min1, max1) &&
      Math.min(min0, max0) <= Math.max(min1, max1)
  }

  getPosition(el) {
    const elTop = el.offsetTop
    const tBodyScrollTop = this.tableContainer.scrollTop

    return elTop - tBodyScrollTop
  }

  getLine(domLine) {
    const itemOrder = domLine.querySelector('.item-order')
    return this.scheduleTemplate.lines.find(el => el.rowOrder === +itemOrder.innerHTML)
  }

   getRowTargetPositionInfo(event) {
    const dragRow = this.getTargetRow(event.target)
    const rowOrder = dragRow.dataset.rowOrder

    if (rowOrder) {
      const targetRow = document.querySelector(`[data-row-order="${rowOrder}"]`)

      if (targetRow) {
        // получим номер дня строки на позицию выше вставленной или если её нет, то строки ниже (в рамках раздела)
        return this.getNearestRowDayNumber(targetRow)
      }
    }
  }

  getNearestRowDayNumber(targetRow) {
    const prevRow = targetRow.previousElementSibling, nextRow = targetRow.nextElementSibling
    let rowInfo = null

    if (prevRow?.classList.contains('body-row')) {
      rowInfo = {
        dayNumber: +prevRow.dataset.rowDayNumber,
        rowOrder: +prevRow.dataset.rowOrder,
        setUnderRow: true
      }
    } else if (nextRow?.classList.contains('body-row')) {
      rowInfo = {
        dayNumber: +nextRow.dataset.rowDayNumber,
        rowOrder: +nextRow.dataset.rowOrder,
        setUnderRow: false
      }
    }

    return rowInfo
  }
}