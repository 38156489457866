export class Work {
  constructor() {
    this.workStatusCheck = false
    this._workCountry = null
    this._workState = null
    this.workTown = null
    this.workTownOutList = null
    this.workPlaceOutList = null
    this.workPlaceAddress = null
    this.workIsSZGMUEmployee = false
    this.workEnvironment = null
    this.workIsEmploymentService = false

    this.workLengthTotalYear = null
    this.workLengthTotalMonth = null
    this.workLengthYear = null
    this.workLengthMonth = null

    this.workCategory = null
    this.workTitle = null
    this.workTitleOutList = null
    this.workPosition = null
    this.workIsManager = false
    this.workIsGoverEmpl = false
    this._workMilitaryServDismiss = false
    this.workMilitaryServDismissThisYear = false
    this._workIsTeacher = false
    this.workEduInstitution = null

    this.workIsRuralAreaDoctor = false
    this.workIsEmergencyDoctor = false
    this.workIsDistrictDoctor = false

    // служебные
    this._workTownCheck = null
    this._workTitleCheck = null

    // храним эти свойства, чтобы после пересборки не пропадали месяц и год стажа
    this.workLengthTotal = null
    this.workLengthSpec = null
  }

  get isRusWorkCountry() {
    return this.workCountry?.countryRegionId === 'РФ'
  }

  get workCountry() {
    return this._workCountry
  }
  set workCountry(val) {
    this._workCountry = val
    this.workState = null
  }

  get workState() {
    return this._workState
  }
  set workState(val) {
    this._workState = val
    this.workTown = null
    this.workTownOutList = null
  }

  get workTownCheck() {
    return this._workTownCheck
  }
  set workTownCheck(val) {
    this._workTownCheck = val
    val ? this.workTown = null : this.workTownOutList = null
  }

  get workTitleCheck() {
    return this._workTitleCheck
  }
  set workTitleCheck(val) {
    this._workTitleCheck = val
    val ? this.workTitle = null : this.workTitleOutList = null
  }

  get workMilitaryServDismiss() {
    return this._workMilitaryServDismiss
  }
  set workMilitaryServDismiss(val) {
    this._workMilitaryServDismiss = val
    this.workMilitaryServDismissThisYear = false
  }

  get workIsTeacher() {
    return this._workIsTeacher
  }
  set workIsTeacher(val) {
    this._workIsTeacher = val
    this.workEduInstitution = null
  }

  formatWorkLength(years = 0, months = 0) {
    return years === 0 && months === 0 ? null : +years + +(+months / 12).toFixed(2)
  }

  reverseFormatWorkLength(workLength) {
    const years = parseInt(workLength)
    const months = Math.round((+workLength - years) * 12)

    return [years, months]
  }

  getForApi() {
    return {
      workStatusCheck: this.workStatusCheck,
      workCountry: this.workCountry,
      workState: this.workState,
      workTown: this.workTown,
      workTownOutList: this.workTownOutList,
      workPlaceOutList: this.workPlaceOutList,
      workPlaceAddress: this.workPlaceAddress,
      workIsSZGMUEmployee: this.workIsSZGMUEmployee,
      workEnvironment: this.workEnvironment,
      workIsEmploymentService: this.workIsEmploymentService,
      workLengthTotal: this.formatWorkLength(this.workLengthTotalYear, this.workLengthTotalMonth),
      workLengthSpec: this.formatWorkLength(this.workLengthYear, this.workLengthMonth),

      workCategory: this.workCategory,
      workTitle: this.workTitle,
      workTitleOutList: this.workTitleOutList,
      workPosition: this.workPosition,
      workIsManager: this.workIsManager,
      workIsGoverEmpl: this.workIsGoverEmpl,
      workMilitaryServDismiss: this.workMilitaryServDismiss,
      workMilitaryServDismissThisYear: this.workMilitaryServDismissThisYear,
      workIsTeacher: this.workIsTeacher,
      workEduInstitution: this.workEduInstitution,

      workIsRuralAreaDoctor: this.workIsRuralAreaDoctor,
      workIsEmergencyDoctor: this.workIsEmergencyDoctor,
      workIsDistrictDoctor: this.workIsDistrictDoctor
    }
  }

  static buildFromAPI(obj) {
    const _this = new Work()

    if (obj) {
      _this.workStatusCheck = obj.workStatusCheck
      _this._workCountry = obj.workCountry
      _this._workState = obj.workState
      _this.workTown = obj.workTown
      _this.workTownOutList = obj.workTownOutList
      _this._workTownCheck = !!obj.workTownOutList
      _this.workPlaceOutList = obj.workPlaceOutList
      _this.workPlaceAddress = obj.workPlaceAddress
      _this.workIsSZGMUEmployee = obj.workIsSZGMUEmployee || false
      _this.workEnvironment = obj.workEnvironment
      _this.workIsEmploymentService = obj.workIsEmploymentService || false
      _this.workLengthTotalYear = _this.reverseFormatWorkLength(obj.workLengthTotal)[0]
      _this.workLengthTotalMonth = _this.reverseFormatWorkLength(obj.workLengthTotal)[1]
      _this.workLengthYear = _this.reverseFormatWorkLength(obj.workLengthSpec)[0]
      _this.workLengthMonth = _this.reverseFormatWorkLength(obj.workLengthSpec)[1]

      _this.workCategory = obj.workCategory
      _this.workTitle = obj.workTitle
      _this.workTitleOutList = obj.workTitleOutList
      _this._workTitleCheck = !!obj.workTitleOutList
      _this.workPosition = obj.workPosition
      _this.workIsManager = obj.workIsManager || false
      _this.workIsGoverEmpl = obj.workIsGoverEmpl || false
      _this._workMilitaryServDismiss = obj.workMilitaryServDismiss || false
      _this.workMilitaryServDismissThisYear = obj.workMilitaryServDismissThisYear || false
      _this._workIsTeacher = obj.workIsTeacher || false
      _this.workEduInstitution = obj.workEduInstitution

      _this.workIsRuralAreaDoctor = obj.workIsRuralAreaDoctor || false
      _this.workIsEmergencyDoctor = obj.workIsEmergencyDoctor || false
      _this.workIsDistrictDoctor = obj.workIsDistrictDoctor || false

      _this.workLengthTotal = obj.workLengthTotal
      _this.workLengthSpec = obj.workLengthSpec
    }

    return _this
  }
}