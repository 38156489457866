import { formatDateReverseToISO, formatDateReverseFromISO } from '@/scripts'

export class Protocols {
  constructor() {
    this.list = []
  }

  updateProtocol(protocol) {
    const index = this.list.findIndex(el => el.fileDpoCardId === protocol.fileDpoCardId)
    this.list.splice(index, 1, Protocol.buildFromAPI(protocol))
  }

  buildFromAPI(list) {
    if (list) {
      this.list = list.map(el => Protocol.buildFromAPI(el))
    }

    return this
  }
}

export class Protocol {
  constructor() {
    this.fileDpoCardId = null
    this.cycleId = null
    this.fullName = null
    this.date = null
    this.number = null
  }

  getForAPI() {
    return {
      fileDpoCardId: this.fileDpoCardId,
      cycleId: this.cycleId,
      fullName: this.fullName,
      date: formatDateReverseToISO(this.date),
      number: this.number || null
    }
  }

  static buildFromAPI(obj) {
    const _this = new Protocol()

    if (obj) {
      _this.fileDpoCardId = obj.fileDpoCardId || null
      _this.cycleId = obj.cycleId || null
      _this.fullName = obj.fullName || null
      _this.date = formatDateReverseFromISO(obj.date)
      _this.number = obj.number || null
    }

    return _this
  }
}