export const formatDate = date => {
  return date ? date.toLocaleString().replace(/(\d{2}).(\d{2}).(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$1.$2.$3') : null
}

export const formatDateToISO = date => {
  return date ? date.toLocaleString().replace(/(\d{2}).(\d{2}).(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1') : null
}

export const formatDateReverseFromISO = date => {
  return date ? date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1') : null
}

export const formatDateReverseToISO = date => {
  return date ? date.replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1') : null
}

export const getDayName = date => {
  const dayNames = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']
  const d = new Date(date)

  return dayNames[d.getDay()]
}

export const getDateWithMonthName = date => {
  const [year, ,day] = date.split('-')
  const monthNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
  const d = new Date(date)

  return `${day} ${monthNames[d.getMonth()]} ${year}`
}