export class Employee {
  constructor() {
    this.deptId = null           // 29
    this.deptName = null         // "Кафедра терапии, ревматологии, экспертизы временной нетрудоспособности"
    this.lastName = null         // "Мазуров"
    this.firstName = null        // "Вадим"
    this.middleName = null       // "Иванович"
    this.fullName = null         // "Мазуров Вадим Иванович"
    this.shortName = null        // "Мазуров В И"
    this.emplId = null           // 3567
    this.emplNumb = null         // "MECH-4204"
    this.emplRateId = null       // 137781
    this.emplRateNumb = null     // "MECH-133132"
    this.emplCategoryId = null   // "проф.преп. состав"
    this.emplWorkType = null     // "основной"
    this.emplTitleCode = null    // "Заведующий кафедрой"
    this.isAdjacent = null       // false
    this.isHourlyPaym = null     // false
    this.isActing = null         // false
    this.isVacation = null       // false
  }

  get employeeInfo() {
    return `${this.shortName} (${this.emplWorkType}) ${this.emplTitleCode}`
  }

  getForAPI() {
    return {
      deptId: this.deptId,
      deptName: this.deptName,
      lastName: this.lastName,
      firstName: this.firstName,
      middleName: this.middleName,
      fullName: this.fullName,
      shortName: this.shortName,
      emplId: this.emplId,
      emplNumb: this.emplNumb,
      emplRateId: this.emplRateId,
      emplRateNumb: this.emplRateNumb,
      emplCategoryId: this.emplCategoryId,
      emplWorkType: this.emplWorkType,
      emplTitleCode: this.emplTitleCode,
      isAdjacent: this.isAdjacent,
      isHourlyPaym: this.isHourlyPaym,
      isActing: this.isActing,
      isVacation: this.isVacation
    }
  }

  static buildFromAPI(obj) {
    const _this = new Employee()

    if (obj) {
      _this.deptId = obj.deptId || null
      _this.deptName = obj.deptName || null
      _this.lastName = obj.lastName
      _this.firstName = obj.firstName
      _this.middleName = obj.middleName
      _this.fullName = obj.fullName
      _this.shortName = obj.shortName
      _this.emplId = obj.emplId
      _this.emplNumb = obj.emplNumb
      _this.emplRateId = obj.emplRateId
      _this.emplRateNumb = obj.emplRateNumb
      _this.emplCategoryId = obj.emplCategoryId
      _this.emplWorkType = obj.emplWorkType
      _this.emplTitleCode = obj.emplTitleCode
      _this.isAdjacent = obj.isAdjacent
      _this.isHourlyPaym = obj.isHourlyPaym
      _this.isActing = obj.isActing
      _this.isVacation = obj.isVacation
    }

    return _this
  }
}


export class OrderEmployees {
  constructor() {
    this.list = []
  }

  showAddBtn(cycleStaffType) {
    const countCycleStaffType = this.list.filter(el => el.cycleStaffType.id === cycleStaffType.id).length

    switch(cycleStaffType.id) {
      case 1: return countCycleStaffType < 20
      case 2: return countCycleStaffType < 5
      case 3: return countCycleStaffType < 99
    }
  }

  showRemoveBtn(item) {
    return this.list.filter(el => el.cycleStaffType.id === item.cycleStaffType.id).length > 1
  }

  setDefaultOrderEmployees() {
    const cycleStaffTypes = [
      { id: 1, name: "председатель", isExamCom: 1 },
      { id: 2, name: "секретарь", isExamCom: 1 },
      { id: 3, name: "члены комиссии", isExamCom: 1 }
    ]

    cycleStaffTypes.forEach(cycleStaffType => {
      const hasCycleStaffType = this.list.some(el => el.cycleStaffType.id === cycleStaffType.id)

      !hasCycleStaffType && this.addOrderEmployee(cycleStaffType)
    })
  }

  addOrderEmployee(cycleStaffType) {
    const orderEmployee = new OrderEmployee()
    orderEmployee.cycleStaffType = cycleStaffType
    this.list.push(orderEmployee)
  }

  removeOrderEmployee(item) {
    const index = this.list.findIndex(el => el === item)
    this.list.splice(index, 1)
  }

  getForAPI(commissionOrderId, specialtyTableId) {
    return {
      commissionOrderId,
      specialtyTableId,
      lines: this.list.filter(el => el.employee.emplId).map(el => el.getForAPI())
    }
  }

  buildFromAPI(obj) {
    if (obj) {
      this.list = obj.map(el => OrderEmployee.buildFromAPI(el))
      this.setDefaultOrderEmployees()
    }

    return this
  }
}


export class OrderEmployee {
  constructor() {
    this.employee = new Employee()
    this.commissionOrderLineId = null
    this.cycleStaffType = null

    // служебные
    this.findLoading = false
    this.employeesList = []
  }

  getForAPI() {
    return {
      ...this.employee.getForAPI(),
      deptId: null,
      deptName: null,
      commissionOrderLineId: null,
      cycleStaffType: this.cycleStaffType
    }
  }

  clear() {
    this.employee = new Employee()
    this.commissionOrderLineId = null
    this.findLoading = false
    this.employeesList = []
  }

  static buildFromAPI(obj) {
    const _this = new OrderEmployee()

    if (obj) {
      _this.employee = Employee.buildFromAPI(obj)
      _this.commissionOrderLineId = obj.commissionOrderLineId
      _this.cycleStaffType = obj.cycleStaffType
      _this.employeesList = [Employee.buildFromAPI(obj)]
    }

    return _this
  }
}