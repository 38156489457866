export class Train {
  constructor() {
    this.environment = null
    this.contractNum = null
    this.contractDate = null
    this.enrollmentDate = null
    this.enrollmentState = false
    this.isHotel = false
    this.listenerNumber = null
    this.nmo = null
    this.retraining = false
    this.cycleDocumentType = null
  }

  getForApi() {
    return {
      environment: this.environment,
      contractNum: this.contractNum,
      contractDate: this.contractDate,
      enrollmentDate: this.enrollmentDate,
      enrollmentState: this.enrollmentState,
      isHotel: this.isHotel,
      listenerNumber: this.listenerNumber,
      nmo: this.nmo,
      retraining: this.retraining,
      cycleDocumentType: this.cycleDocumentType
    }
  }

  static buildFromAPI(obj) {
    const _this = new Train()

    if (obj) {
      _this.environment = obj.environment
      _this.contractNum = obj.contractNum
      _this.contractDate = obj.contractDate
      _this.enrollmentDate = obj.enrollmentDate
      _this.enrollmentState = obj.enrollmentState || false
      _this.isHotel = obj.isHotel || false
      _this.listenerNumber = obj.listenerNumber
      _this.nmo = obj.nmo
      _this.retraining = obj.retraining || false
      _this.cycleDocumentType = obj.cycleDocumentType
    }

    return _this
  }
}