export class CycleAttributes {
  constructor() {
    this.learningContingent = null
    this.typeRetraining = null
  }

  getForAPI() {
    return {
      learningContingent: this.learningContingent,
      typeRetraining: this.typeRetraining
    }
  }

  static buildFromAPI(obj) {
    const _this = new CycleAttributes()

    if (obj) {
      _this.learningContingent = obj.learningContingent || null
      _this.typeRetraining = obj.typeRetraining || null
    }

    return _this
  }
}