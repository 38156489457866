export class AddEducation {
  constructor() {
    this.addEduBeginDate = null
    this.addEduEndDate = null
    this.addEduSchool = null
    this.addEduForm = null
    this.addEduCountHours = null
    this.addEduPlace = null
    this.addEduCardType = null
    this.addEduCardSeries = null
    this.addEduCardNumber = null
    this.addEduCardIssueDate = null
    this.addEduSpecialitySpc = null
    this.addEduSpecialityInt = null
    this.addEduSpecialityOrd = null
    this.addEduSpecialityIdAsp = null
    this.addEduSpecialityDpo = null
  }

  getForApi() {
    return {
      addEduBeginDate: this.addEduBeginDate,
      addEduEndDate: this.addEduEndDate,
      addEduSchool: this.addEduSchool,
      addEduForm: this.addEduForm,
      addEduCountHours: this.addEduCountHours,
      addEduPlace: this.addEduPlace,
      addEduCardType: this.addEduCardType,
      addEduCardSeries: this.addEduCardSeries,
      addEduCardNumber: this.addEduCardNumber,
      addEduCardIssueDate: this.addEduCardIssueDate,
      addEduSpecialitySpc: this.addEduSpecialitySpc,
      addEduSpecialityInt: this.addEduSpecialityInt,
      addEduSpecialityOrd: this.addEduSpecialityOrd,
      addEduSpecialityIdAsp: this.addEduSpecialityIdAsp,
      addEduSpecialityDpo: this.addEduSpecialityDpo
    }
  }

  static buildFromAPI(obj) {
    const _this = new AddEducation()

    if (obj) {
      _this.addEduBeginDate = obj.addEduBeginDate
      _this.addEduEndDate = obj.addEduEndDate
      _this.addEduSchool = obj.addEduSchool
      _this.addEduForm = obj.addEduForm
      _this.addEduCountHours = obj.addEduCountHours
      _this.addEduPlace = obj.addEduPlace
      _this.addEduCardType = obj.addEduCardType
      _this.addEduCardSeries = obj.addEduCardSeries
      _this.addEduCardNumber = obj.addEduCardNumber
      _this.addEduCardIssueDate = obj.addEduCardIssueDate
      _this.addEduSpecialitySpc = obj.addEduSpecialitySpc
      _this.addEduSpecialityInt = obj.addEduSpecialityInt
      _this.addEduSpecialityOrd = obj.addEduSpecialityOrd
      _this.addEduSpecialityIdAsp = obj.addEduSpecialityIdAsp
      _this.addEduSpecialityDpo = obj.addEduSpecialityDpo
    }

    return _this
  }
}