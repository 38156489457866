import { getFullName } from '@/scripts'

export class PersonalData {
  constructor() {
    this.id = null
    this.nationality = null
    this.cycleId = null
    this._lastName = null
    this._firstName = null
    this._middleName = null
    this._lastNameGen = null
    this._firstNameGen = null
    this._middleNameGen = null
    this.gender = null
    this.snils = null
    this.birthDate = null

    this._identityCardType = null
    this.migrationServiceIdentifier = null
    this.identityCardSeries = null
    this.identityCardNumber = null
    this.identityCardIssueDate = null
    this.identityCardIssueBy = null

    this.zipCode = null
    this._countryRegion = null
    this.state = null
    this.addressTown = null
    this.address = null

    this.homeZipCode = null
    this._homeCountryRegion = null
    this.homeState = null
    this.homeAddressTown = null
    this.homeAddress = null

    this.cellularPhone = null
    this.homePhoneNumber = null
    this.email = null
  }

  get isRussian() {
    return this.nationality?.id === 192
  }

  get isMigrationIdentityCard() {
    return this.identityCardType?.identityCardCode === 'ИнострПасп'
  }

  get isPassportIdentityCard() {
    return this.identityCardType?.identityCardCode === 'Паспорт РФ'
  }

  get isRusCountryRegion() {
    return this.countryRegion?.countryRegionId === 'РФ'
  }

  get isRusHomeCountryRegion() {
    return this.homeCountryRegion?.countryRegionId === 'РФ'
  }

  get lastName() {
    return this._lastName
  }
  set lastName(val) {
    this._lastName = val ? val.charAt(0).toUpperCase() + val.slice(1) : null
  }

  get lastNameGen() {
    return this._lastNameGen
  }
  set lastNameGen(val) {
    this._lastNameGen = val ? val.charAt(0).toUpperCase() + val.slice(1) : null
  }

  get firstName() {
    return this._firstName
  }
  set firstName(val) {
    this._firstName = val ? val.charAt(0).toUpperCase() + val.slice(1) : null
  }

  get firstNameGen() {
    return this._firstNameGen
  }
  set firstNameGen(val) {
    this._firstNameGen = val ? val.charAt(0).toUpperCase() + val.slice(1) : null
  }

  get middleName() {
    return this._middleName
  }
  set middleName(val) {
    this._middleName = val ? val.charAt(0).toUpperCase() + val.slice(1) : null
  }

  get middleNameGen() {
    return this._middleNameGen
  }
  set middleNameGen(val) {
    this._middleNameGen = val ? val.charAt(0).toUpperCase() + val.slice(1) : null
  }

  get countryRegion() {
    return this._countryRegion
  }
  set countryRegion(val) {
    this._countryRegion = val
    this.state = null
    this.addressTown = null
  }

  get homeCountryRegion() {
    return this._homeCountryRegion
  }
  set homeCountryRegion(val) {
    this._homeCountryRegion = val
    this.homeState = null
    this.homeAddressTown = null
  }

  get identityCardType() {
    return this._identityCardType
  }
  set identityCardType(val) {
    this._identityCardType = val
    this.migrationServiceIdentifier = null
    this.identityCardSeries = null
    this.identityCardNumber = null
    this.identityCardIssueBy = null
    this.identityCardIssueDate = null
  }

  copyRegAddress(val) {
    if (val) {
      this.homeZipCode = this.zipCode
      this.homeCountryRegion = this.countryRegion
      this.homeState = this.state
      this.homeAddressTown = this.addressTown
      this.homeAddress = this.address
    }
  }

  getForApi() {
    return {
      id: this.id,
      nationality: this.nationality,
      cycleId: this.cycleId,
      lastName: this.lastName,
      firstName: this.firstName,
      middleName: this.middleName,
      lastNameGen: this.lastNameGen,
      firstNameGen: this.firstNameGen,
      middleNameGen: this.middleNameGen,
      fullName: getFullName(this.lastName, this.firstName, this.middleName),
      gender: this.gender,
      snils: this.snils,
      birthDate: this.birthDate,

      identityCardType: this.identityCardType,
      migrationServiceIdentifier: this.migrationServiceIdentifier,
      identityCardSeries: this.identityCardSeries,
      identityCardNumber: this.identityCardNumber,
      identityCardIssueDate: this.identityCardIssueDate,
      identityCardIssueBy: this.identityCardIssueBy,

      zipCode: this.zipCode,
      countryRegion: this.countryRegion,
      state: this.state,
      addressTown: this.addressTown,
      address: this.address,

      homeZipCode: this.homeZipCode,
      homeCountryRegion: this.homeCountryRegion,
      homeState: this.homeState,
      homeAddressTown: this.homeAddressTown,
      homeAddress: this.homeAddress,

      cellularPhone: this.cellularPhone,
      homePhoneNumber: this.homePhoneNumber,
      email: this.email
    }
  }

  static buildFromAPI(obj) {
    const _this = new PersonalData()

    if (obj) {
      _this.id = obj.id
      _this.nationality = obj.nationality
      _this.cycleId = obj.cycleId
      _this.lastName = obj.lastName
      _this.firstName = obj.firstName
      _this.middleName = obj.middleName
      _this.lastNameGen = obj.lastNameGen
      _this.firstNameGen = obj.firstNameGen
      _this.middleNameGen = obj.middleNameGen
      _this.gender = obj.gender
      _this.snils = obj.snils
      _this.birthDate = obj.birthDate

      _this._identityCardType = obj.identityCardType
      _this.migrationServiceIdentifier = obj.migrationServiceIdentifier
      _this.identityCardSeries = obj.identityCardSeries
      _this.identityCardNumber = obj.identityCardNumber
      _this.identityCardIssueDate = obj.identityCardIssueDate
      _this.identityCardIssueBy = obj.identityCardIssueBy

      _this.zipCode = obj.zipCode
      _this._countryRegion = obj.countryRegion
      _this.state = obj.state
      _this.addressTown = obj.addressTown
      _this.address = obj.address

      _this.homeZipCode = obj.homeZipCode
      _this._homeCountryRegion = obj.homeCountryRegion
      _this.homeState = obj.homeState
      _this.homeAddressTown = obj.homeAddressTown
      _this.homeAddress = obj.homeAddress

      _this.cellularPhone = obj.cellularPhone
      _this.homePhoneNumber = obj.homePhoneNumber
      _this.email = obj.email
    }

    return _this
  }
}