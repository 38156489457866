<template>
	<v-row justify="center" class="ma-0">
		<v-dialog :value="dialog" max-width="550" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">Инструкции</v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pa-0 pt-5">
                <ul class="links">
                  <li v-for="instruction in computedInstructions" :key="instruction.id">
                    <a class="link" :href="instruction.link" target="_blank">{{ instruction.name }}</a>
                  </li>
                </ul>
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="lightGrey" @click="closeDialog">Понятно</v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		dialog: Boolean
	},

  data: () => ({
    instructions: [
      {
        id: 1,
        name: 'Инструкция пользователя',
        link: '/Инструкция ДПО.pdf',
        allowRoles: ['*']
      },
      {
        id: 2,
        name: 'Инструкция по работе с разделами',
        link: '/Инструкция по работе с Разделами.pdf',
        allowRoles: ['*']
      }
    ]
  }),

  computed: {
    ...mapGetters('user', ['allUserRoles']),

    computedInstructions() {
      return this.instructions.filter(item => {
        return item.allowRoles.some(role => this.allUserRoles.includes(role) || role === '*')
      })
    }
  },

	methods: {
    closeDialog() {
			this.$emit('closeInstructionsDialog')
		}
	}
}
</script>

<style scoped>
ul.links {
  padding-left: 0;
}

ul.links li {
  list-style-type: none;
  font-size: 16px;
  margin-bottom: 16px;
}

ul.links li:last-child {
  margin-bottom: 0;
}

.link {
  text-decoration: none;
  color: #37474f;
  border-bottom: 1px solid #81939c;
  padding-bottom: 1px;
  transition: border-bottom-color .2s ease
}

.link:hover {
  border-bottom-color: white;
}
</style>