import { repository, baseURL } from '@/api/base'

export default {
  async getEventPlaces(departmentId) {
    const {data} = await repository.get(`${baseURL}/dictionary/${departmentId}/eventPlace`)
    return data
  },

  async addEventPlace(departmentId, body) {
    const { data } = await repository.post(`${baseURL}/dictionary/${departmentId}/eventPlace`, body)
    return data
  },

  async updateEventPlace(departmentId, body) {
    const { data } = await repository.put(`${baseURL}/dictionary/${departmentId}/eventPlace`, body)
    return data
  },

  async removeEventPlace(departmentId, id) {
    return await repository.delete(`${baseURL}/dictionary/${departmentId}/eventPlace/${id}`)
  }
}