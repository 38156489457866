import { PersonalData, Education, Work, Train, AddEducation } from '@/models'

export class ListenerCard {
  constructor() {
    this.personalData = new PersonalData()
    this.education = new Education()
    this.work = new Work()
    this.train = new Train()
    this.addEducation = new AddEducation()
  }

  clearAddEducation() {
    this.addEducation = new AddEducation()
  }


  getForAPI() {
    return {
      ...this.personalData.getForApi(),
      ...this.education.getForApi(),
      ...this.work.getForApi(),
      ...this.train.getForApi(),
      ...this.addEducation.getForApi()
    }
  }

  buildFromAPI(obj) {
    if (obj) {
      this.personalData = PersonalData.buildFromAPI(obj)
      this.education = Education.buildFromAPI(obj)
      this.work = Work.buildFromAPI(obj)
      this.train = Train.buildFromAPI(obj)
      this.addEducation = AddEducation.buildFromAPI(obj)
    }
  }
}
