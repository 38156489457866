export class AcademicWork {
  constructor() {
    this.id = null
    this.academicWorkId = null
    this.academicWorkTypeId = null
    this.name = null
    this.isRelevant = null // используется на странице настроек, чтобы указывать актуальные типы занятий
  }

  get shortName() {
    if (this.name === 'стажировка') return 'СТ' // исключение, т.к. два типа занятий на "С"
    return this.name ? this.name.split(' ').map(e => e.slice(0, 1).toUpperCase()).join('') : null
  }

  getForAPI() {
    return {
      id: this.id,
      academicWorkId: this.academicWorkId,
      academicWorkTypeId: this.academicWorkTypeId,
      name: this.name,
      isRelevant: this.isRelevant
    }
  }

  static buildFromAPI(obj) {
    const _this = new AcademicWork()

    if (obj) {
      _this.id = obj.id
      _this.academicWorkId = obj.academicWorkId
      _this.academicWorkTypeId = obj.academicWorkTypeId
      _this.name = obj.name
      _this.isRelevant = obj.isRelevant
    }

    return _this
  }
}