import { repository, baseURL } from '@/api/base'

export default {
  async findAll(departmentId) {
    const { data } = await repository.get(`${baseURL}/dictionary/chapter/findAll/${departmentId}`)
    return data
  },

  async add(body) {
    const { data } = await repository.post(`${baseURL}/dictionary/chapter`, body)
    return data
  },

  async update(body) {
    const { data } = await repository.put(`${baseURL}/dictionary/chapter`, body)
    return data
  },

  async remove(id) {
    return await repository.delete(`${baseURL}/dictionary/chapter/${id}`)
  }
}