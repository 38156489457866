export class CycleHolidays {
  constructor() {
    this.list = []
  }

  get datesList() {
    return this.list.map(el => el.date)
  }

  setDatesList(list) {
    const addDate = list.find(i => !this.datesList.includes(i))
    const removeDate = this.datesList.find(i => !list.includes(i))

    addDate && this.addHoliday(addDate)
    removeDate && this.removeHoliday(removeDate)
  }

  updateDatesList(list) {
    this.list = list.map(el => CycleHoliday.buildFromAPI(el))
  }

  sortByDate() {
    this.list.sort((a, b) => new Date(a.date) - new Date(b.date))
  }

  addHoliday(date) {
    this.list.push(new CycleHoliday(date))
    this.sortByDate()
  }

  removeHoliday(date) {
    const index = this.list.findIndex(el => el.date === date)
    index !== -1 && this.list.splice(index, 1)
  }

  getForAPI() {
    return this.list.map(el => el.getForAPI())
  }

  buildFromAPI(list) {
    if (list) {
      this.list = list.map(el => CycleHoliday.buildFromAPI(el))
      this.sortByDate()
    }

    return this
  }
}

class CycleHoliday {
  constructor(date) {
    this.holidayId = null
    this.date = date || null
  }

  getForAPI() {
    return {
      holidayId: this.holidayId,
      date: this.date
    }
  }

  static buildFromAPI(obj) {
    const _this = new CycleHoliday()

    if (obj) {
      _this.holidayId = obj.holidayId || null
      _this.date = obj.date || null
    }

    return _this
  }
}