import { repository, cancelToken, baseURL } from '@/api/base'
let cancel = null

export default {
  async getEducationTypes() {
    const { data } = await repository.get(baseURL + '/dictionary/educationType')
    return data
  },

  async getCycleStates() {
    const { data } = await repository.get(baseURL + '/dictionary/cycleState')
    return data
  },

  async getGradeSystems() {
    const { data } = await repository.get(baseURL + '/dictionary/gradeSystem')
    return data
  },

  async getGrades() {
    const { data } = await repository.get(baseURL + '/dictionary/grade')
    return data
  },

  async getAcademicWorks() {
    const { data } = await repository.get(baseURL + '/dictionary/academicWork')
    return data
  },

  async getAdressCountryRegions() {
    const { data } = await repository.get(baseURL + `/dictionary/adressCountryRegion`)
    const firstElement = data.find(item => item.countryRegionId === "РФ")
    const otherElements = data.filter(item => item.countryRegionId !== "РФ")
    return [firstElement, ...otherElements]
  },

  async getAddressStates() {
    const { data } = await repository.get(baseURL + '/dictionary/addressState')
    const firstElement = data.find(item => item.name === "Санкт-Петербург")
    const otherElements = data.filter(item => item.name !== "Санкт-Петербург")
    return [firstElement, ...otherElements]
  },

  async getCities(stateId) {
    const { data } = await repository.get(baseURL + `/dictionary/addressTown/${stateId}/find`)

    if (stateId === 44) {
      // регион Москва
      const firstElement = data.find(item => item.name === "Москва")
      const otherElements = data.filter(item => item.name !== "Москва")
      return [firstElement, ...otherElements]
    } else if (stateId === 68) {
      // регион Санкт-Петербург
      const firstElement = data.find(item => item.name === "Санкт-Петербург")
      const otherElements = data.filter(item => item.name !== "Санкт-Петербург")
      return [firstElement, ...otherElements]
    } else {
      return data
    }
  },

  async getGenders() {
    const { data } = await repository.get(baseURL + `/dictionary/gender`)
    return data
  },

  async getIdentityCards() {
    const { data } = await repository.get(baseURL + `/dictionary/identityCard`)
    return data
  },

  async getEduLevels() {
    const { data } = await repository.get(baseURL + '/dictionary/eduLevel')
    return data
  },

  async getHighSchools() {
    const { data } = await repository.get(baseURL + '/dictionary/highSchool')
    return data.sort((a, b) => a.shortName.localeCompare(b.shortName))
  },

  async getEduYears() {
    const { data } = await repository.get(baseURL + '/dictionary/eduYear')
    return data.sort((a, b) => b.name.localeCompare(a.name))
  },

  async getDiplomaTypes() {
    const { data } = await repository.get(baseURL + '/dictionary/diplomaTypes')
    return data
  },

  async getSpeciality(educationLevel) {
    const { data } = await repository.get(baseURL + `/dictionary/${educationLevel}/speciality`)
    return data.sort((a, b) => a.description.localeCompare(b.description))
  },

  async getSpecialityTable() {
    const { data } = await repository.get(baseURL + '/dictionary/specialityTable')
    return data.sort((a, b) => a.specialtyName.localeCompare(b.specialtyName))
  },

  async getSpecialityTableByEduLevel(educationLevel) {
    const { data } = await repository.get(baseURL + `/dictionary/${educationLevel}/specialityTable`)
    return data.sort((a, b) => a.specialtyName.localeCompare(b.specialtyName))
  },

  async getAcademicDegrees() {
    const { data } = await repository.get(baseURL + '/dictionary/academicDegree')
    return data
  },

  async getAcademicCategories() {
    const { data } = await repository.get(baseURL + '/dictionary/academicCategory')
    return data
  },

  async getCategoryPersons() {
    const { data } = await repository.get(baseURL + '/dictionary/categoryPerson')
    return data
  },

  async getPayWays() {
    const { data } = await repository.get(baseURL + '/dictionary/payWay')
    return data
  },

  async getCategoryPositions() {
    const { data } = await repository.get(baseURL + '/dictionary/categoryPosition')
    return data
  },

  async getEducationalInstitutions() {
    const { data } = await repository.get(baseURL + '/dictionary/educationalInstitution/')
    return data
  },

  async findPersonTitles(searchString) {
    cancel && cancel()

    const { data } = await repository.get(baseURL + `/dictionary/${searchString}/personTitle`, {
      cancelToken: new cancelToken(c => cancel = c)
    })

    return data
  },

  async getPayWayCycles() {
    const { data } = await repository.get(baseURL + '/dictionary/payWayCycle')
    return data
  },

  async getEduFormCycles() {
    const { data } = await repository.get(baseURL + '/dictionary/eduFormCycle')
    return data
  },

  async getAcademicWorksForSettings() {
    const { data } = await repository.get(baseURL + '/dictionary/academicWorksAll')
    return data.filter(el => el.academicWorkId)
  },

  async updateAcademicWorksForSettings(body) {
    const { data } = await repository.put(baseURL + '/dictionary/academicWorksAll', body)
    return data
  }
}