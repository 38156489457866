import { getFullName } from '@/scripts'

export class Distributions {
  constructor() {
    this.list = []
  }

  addUnDistribution(unDistribution) {
    this.list.push(Distribution.buildFromAPI(unDistribution))
  }

  updateDistributions(list) {
    const unDistributionList = this.list.filter(el => !el.groupNum)
    this.buildFromAPI(list)
    this.list.push(...unDistributionList)
  }

  buildFromAPI(list) {
    if (list?.length) {
      this.list = list.map(el => Distribution.buildFromAPI(el))
    }

    return this
  }
}

export class Distribution {
  constructor() {
    this.fileDpoCardId = null
    this.cycleId = null
    this.groupNum = null
    this.groupType = null
    this.environment = null
    this.lastName = null
    this.firstName = null
    this.middleName = null
    this.fullName = null
  }

  get groupTypeName() {
    return this.groupType?.name || null
  }

  get environmentName() {
    return this.environment?.name || null
  }

  getForAPI() {
    return {
      fileDpoCardId: this.fileDpoCardId,
      cycleId: this.cycleId,
      groupNum: this.groupNum
    }
  }

  static buildFromAPI(obj) {
    const _this = new Distribution()

    if (obj) {
      _this.fileDpoCardId = obj.fileDpoCardId || null
      _this.cycleId = obj.cycleId || null
      _this.groupNum = obj?.groupNum || null
      _this.groupType = obj?.groupType || null
      _this.environment = obj?.environment || null
      _this.lastName = obj?.lastName || null
      _this.firstName = obj?.firstName || null
      _this.middleName = obj?.middleName || null
      _this.fullName = obj?.fullName || getFullName(_this.lastName, _this.firstName, _this.middleName)
    }

    return _this
  }
}