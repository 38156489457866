export class EventPlaces {
  constructor() {
    this.list = []
  }

  addEventPlace(eventPlace) {
    this.list.push(EventPlace.buildFromAPI(eventPlace))
  }

  updateEventPlace(eventPlace) {
    const index = this.list.findIndex(el => el.id === eventPlace.id)
    this.list.splice(index, 1, EventPlace.buildFromAPI(eventPlace))
  }

  removeEventPlace(id) {
    const index = this.list.findIndex(el => el.id === id)
    this.list.splice(index, 1)
  }

  getForAPI() {
    return this.list.map(eventPlace => eventPlace.getForAPI())
  }

  buildFromAPI(obj) {
    if (obj) {
      this.list = obj.map(eventPlace => EventPlace.buildFromAPI(eventPlace))
    }

    return this
  }
}


export class EventPlace {
  constructor() {
    this.id = null
    this.departmentId = null
    this.name = null
    this.address = null
    this.floor = null
    this.place = null
  }

  get fullAddress() {
    return this.floor && this.place ? `${this.address}, ${this.floor} этаж, ${this.place}` : this.address
  }

  getForAPI() {
    return {
      id: this.id,
      departmentId: this.departmentId,
      name: this.name,
      address: this.address,
      floor: this.floor,
      place: this.place
    }
  }

  static buildFromAPI(obj) {
    const _this = new EventPlace()

    if (obj) {
      _this.id = obj.id
      _this.departmentId = obj.departmentId
      _this.name = obj.name
      _this.address = obj.address
      _this.floor = obj.floor
      _this.place = obj.place
    }

    return _this
  }
}