import { formatDateReverseFromISO } from '@/scripts'
import { repository, cancelToken, baseURL } from '@/api/base'
let cancel = null

export default {
  async distributeCycle(cycleId) {
    const { data } = await repository.get(`${baseURL}/dpo/${cycleId}/distributeCycle`)
    return data
  },

  async getDistribution(cycleId) {
    const { data } = await repository.get(`${baseURL}/dpo/${cycleId}/getDistribution`)
    return data.sort((a, b) => (a.lastName && b.lastName) && a.lastName.localeCompare(b.lastName))
  },

  async getUnDistribution(cycleId) {
    const { data } = await repository.get(`${baseURL}/dpo/${cycleId}/getUnDistribution`)
    return data.sort((a, b) => (a.lastName && b.lastName) && a.lastName.localeCompare(b.lastName))
  },

  async setDistribution(body) {
    const { data } = await repository.put(`${baseURL}/dpo/saveDist`, body)
    return data.sort((a, b) => (a.lastName && b.lastName) && a.lastName.localeCompare(b.lastName))
  },

  async getAccountingListeners(cycleId) {
    const { data } = await repository.get(`${baseURL}/dpo/${cycleId}/getListeners`)
    return data.sort((a, b) => (a.fullName && b.fullName) && a.fullName.localeCompare(b.fullName))
  },

  async getListeners(departmentId, pageNumber, body) {
    cancel && cancel()

    const { data } = await repository.post(`${baseURL}/dpo/listenerList/${departmentId}/${pageNumber}`, body, {
      cancelToken: new cancelToken(c => cancel = c)
    })

    return data
  },

  async searchListenersByParams(pageNumber, body) {
    cancel && cancel()

    const { data } = await repository.post(`${baseURL}/dpo/searchListenersByParams/${pageNumber}`, body, {
      cancelToken: new cancelToken(c => cancel = c)
    })

    return data
  },

  async getListenerCard(id) {
    const { data } = await repository.get(`${baseURL}/dpo/card/${id}`)
    return data
  },

  async saveListenerCard(body) {
    return await repository.post(`${baseURL}/dpo/saveCard`, body)
  },

  async updateListenerCard(body) {
    return await repository.put(`${baseURL}/dpo/updateCard`, body)
  },

  async removeListenerCard(id) {
    const { data } = await repository.delete(`${baseURL}/dpo/${id}/removeCard`)
    return data
  },

  async getCardDocs(id) {
    const { data } = await repository.get(`${baseURL}/dpo/card/docs/${id}`)
    return data.map(el => el?.docIssueDate ? { ...el, docIssueDate: formatDateReverseFromISO(el.docIssueDate) } : el)
  }
}