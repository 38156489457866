import Vue from 'vue'
import Vuex from 'vuex'

import system from '@/store/system'
import breakpoint from '@/store/breakpoint'
import user from '@/store/user'
import department from '@/store/department'
import cycle from '@/store/cycle'

Vue.use(Vuex)

export default new Vuex.Store({
	strict: true,

	modules: {
		system,
		breakpoint,
		user,
		department,
		cycle
	}
})