import { repository, baseURL } from "@/api/base"

export default {
  async sendToAx(cycleId) {
    return await repository.get(`${baseURL}/integration/toAx?cycleId=${cycleId}`)
  },

  async sendToAxAdmin(cycleId) {
    return await repository.get(`${baseURL}/integration/toAx/admin?cycleId=${cycleId}`)
  },

  async mergeResultDoc(cycleId) {
    return await repository.get(`${baseURL}/integration/${cycleId}/mergeResultDoc`)
  },

  async getUploadingParameters() {
    const { data } = await repository.get(`${baseURL}/integration/getUploadParam`)
    return data
  },

  async saveUploadingParameter(body) {
    const { data } = await repository.post(`${baseURL}/integration/saveUploadParam`, body)
    return data
  },

  async updateUploadingParameter(body) {
    const { data } = await repository.put(`${baseURL}/integration/updateUploadParam`, body)
    return data
  },

  async removeUploadingParameter(id) {
    return await repository.delete(`${baseURL}/integration/${id}/deleteUploadParam`)
  }
}