export class Cycles {
  constructor() {
    this.list = []
  }

  addCycle(obj) {
    !this.checkCycleInList(obj) && this.list.push(Cycle.buildFromAPI(obj))
  }

  checkCycleInList(obj) {
    return this.list.some(el => el.cycleId === obj.cycleId)
  }

  clearAll() {
    this.list = []
  }
}


export class Cycle {
  constructor() {
    this.cycleId = null            // 61957
    this.cycleNum = null           // 136
    this.departmentId = null       // 482
    this.educationType = null      // {}
    this.name = null               // "Хирургия. Подготовка и прием экзамена на сертификат специалиста"
    this.fromDate = null           // "06-09-2020"
    this.toDate = null             // "16-09-2020"
    this.qtyListPort = 0           // 17
    this.state = null              // "COMPLETE"
    this.unitId = null             // "144ч"
    this.isSchedule = false        // false
    this.listenerCount = null      // 13
    this.groupCnt = null           // 24
    this.groupCntFact = null       // остается
    this.planDist = null
    this.factDist = null
  }

  setIsSchedule(val) {
    this.isSchedule = val
  }

  static buildFromAPI(obj) {
    const _this = new Cycle()

    if (obj) {
      _this.cycleId = obj.cycleId || null
      _this.cycleNum = obj.cycleNum || null
      _this.departmentId = obj.departmentId || null
      _this.educationType = obj.educationType || null
      _this.name = obj.name || null
      _this.fromDate = obj.fromDate || null
      _this.toDate = obj.toDate || null
      _this.qtyListPort = obj.qtyListPort || 0
      _this.state = obj.state || null
      _this.unitId = obj.unitId || null
      _this.isSchedule = obj.isSchedule || false
      _this.listenerCount = obj.listenerCount || null
      _this.groupCnt = obj.groupCnt || null
      _this.groupCntFact = obj.groupCntFact || 0
      _this.planDist = obj.planDist
      _this.factDist = obj.factDist
    }

    return _this
  }
}