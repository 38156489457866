import { repository, baseURL } from "@/api/base"

export default {
  async getExamProtocol(cycleId) {
    const { data } = await repository.get(`${baseURL}/protocol/${cycleId}/getExamProtocol`)
    return data
  },

  async setExamProtocol(cycleId, body) {
    const { data } = await repository.post(`${baseURL}/protocol/${cycleId}/setExamProtocol`, body)
    return data
  },

  async getFinalAttestationProtocols(cycleId) {
    const { data } = await repository.get(`${baseURL}/protocol/${cycleId}/getFinalAttestationProtocols`)
    return data.sort((a, b) => (a.fullName && b.fullName) && a.fullName.localeCompare(b.fullName))
  },

  async setFinalAttestationProtocol(cycleId, body) {
    const { data } = await repository.put(`${baseURL}/protocol/${cycleId}/putFinalAttestationProtocol`, body)
    return data
  },

  async fillProtocolsByCycleId(cycleId) {
    const { data } = await repository.post(`${baseURL}/protocol/${cycleId}/fillProtocolsByCycleId`)
    return data
  }
}