import { vm } from '@/main'
import { departmentApi } from '@/api'
import { getDepartmentFromLocalStorage } from '@/scripts'

export default {
  namespaced: true,

  state: {
    department: null
  },

  getters: {
    department: state => state.department,
    departmentId: state => state.department?.id || null,
    departmentName: state => state.department?.name || null
  },

  mutations: {
    setDepartment(state, payload) {
      state.department = payload
    },

    clearDepartment(state) {
      state.department = null
    }
  },

  actions: {
    setDepartment({ commit }, payload) {
      commit('setDepartment', payload)
    },

    async checkDepartmentInLocalStorage({ state, commit, rootGetters }) {
      const userId = rootGetters['user/userId']

      if (!state.department) {
        const departmentId = getDepartmentFromLocalStorage(userId) || null

        if (departmentId) {
          const department = await departmentApi.getDepartmentById(departmentId)
          commit('setDepartment', department)
        } else if (vm.$route.name !== 'department') {
          await vm.$router.push('/department')
          vm.$toastr('warning', '', 'Для продолжения работы необходимо выбрать кафедру')
          throw new Error('Для продолжения работы необходимо выбрать кафедру')
        }
      }
    }
  }
}