import { repository, baseURL } from '@/api/base'

export default {
  async getCycleAccess() {
    const {data} = await repository.get(`${baseURL}/cycleAccess/default`)
    return data
  },

  async saveCycleAccess(body) {
    const { data } = await repository.post(`${baseURL}/cycleAccess/default`, body)
    return data
  },
}