import _cloneDeep from 'lodash/cloneDeep'
import { formatDateToISO } from '@/scripts/formatDate'
import { AcademicWork, EventPlace, Employee, CycleHolidays } from '@/models'

export class Schedule {
  constructor() {
    this.planSchedule = []
    this.factSchedule = []
    this.holidays = new CycleHolidays()
  }

  getHolidays() {
    return this.holidays.list
  }

  setHolidays(list) {
    this.holidays.buildFromAPI(list)
  }

  generateStartEndTimes(beginTime, lessonMinutes) {
    const [hours, minutes] = beginTime.split(':').map(el => parseInt(el))
    const sumMinutes = hours * 60 + minutes + lessonMinutes
    let startEndTimes = null

    // проверим, если итоговое кол-во минут 1439 (23:59), то возвращаем 23:59
    if (sumMinutes > 1439) {
      startEndTimes = `${beginTime}-23:59`
    } else {
      let totalHours = Math.trunc(sumMinutes / 60)
      let totalMinutes = (sumMinutes % 60)

      if (totalHours < 10) totalHours = `0${totalHours}`
      if (totalMinutes < 10) totalMinutes = `0${totalMinutes}`

      startEndTimes = `${beginTime}-${totalHours}:${totalMinutes}`
    }

    return startEndTimes
  }

  createNewSchedule(fromDate, toDate, params) {
    if (fromDate && toDate && params?.beginTime) {
      const startDate = new Date(fromDate)
      const endDate = new Date(toDate)
      const lessonMinutes = 90

      const holidaysDates = this.getHolidays().map(el => new Date(el.date).getTime())
      const allowDates = []

      for (const d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
        !holidaysDates.includes(d.getTime()) && allowDates.push(formatDateToISO(new Date(d)))
      }

      const startEndTimes = this.generateStartEndTimes(params.beginTime, lessonMinutes)

      allowDates.forEach(date => {
        const line = new ScheduleLine()
        line.isFact = false
        line.date = date
        line.startEndTimes = startEndTimes
        this.planSchedule.push(line)
      })
    }
  }

  createFactSchedule() {
    const copyPlanSchedule = _cloneDeep(this.planSchedule)

    copyPlanSchedule.forEach(el => {
      el.scheduleId = null
      el.isFact = true
    })

    this.factSchedule = copyPlanSchedule
  }

  sortFactLines() {
    this.sortLines('factSchedule')
  }

  sortPlanLines() {
    this.sortLines('planSchedule')
  }

  sortLines(type) {
    this[type].sort((a, b) => a.startEndTimes < b.startEndTimes ? -1 : (a.startEndTimes > b.startEndTimes ? 1 : 0))
    this[type].sort((a, b) => {
      if (a.startEndTimes === b.startEndTimes) {
        return a.groups < b.groups ? -1 : (a.groups > b.groups ? 1 : 0)
      }
    })
    this[type].sort((a, b) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0))
  }

  findLineInList(params) {
    const { date, isFact, index } = params

    if (index >= 0) {
      const lines = isFact ? this.factSchedule : this.planSchedule
      const dateLines = lines.filter(el => el.date === date)

      return lines.findIndex(el => el === dateLines[index])
    }
  }

  addScheduleLine(line) {
    if (line.isFact) {
      this.factSchedule.push(line)
      this.sortFactLines()
    } else {
      this.planSchedule.push(line)
      this.sortPlanLines()
    }
  }

  editScheduleLine(params) {
    const line = params.line
    const indexInList = this.findLineInList(params)

    if (line && indexInList >= 0) {
      if (params.isFact) {
        this.factSchedule.splice(indexInList, 1, line)
        this.sortFactLines()
      } else {
        this.planSchedule.splice(indexInList, 1, line)
        this.sortPlanLines()
      }
    }
  }

  copyScheduleLine(params) {
    const line = params.line
    const indexInList = this.findLineInList(params)

    const cloneLine = _cloneDeep(line)
    cloneLine.scheduleId = null

    if (cloneLine && indexInList >= 0) {
      if (params.isFact) {
        this.factSchedule.splice(indexInList + 1, 0, cloneLine)
        this.sortFactLines()
      } else {
        this.planSchedule.splice(indexInList + 1, 0, cloneLine)
        this.sortPlanLines()
      }
    }
  }

  deleteScheduleLine(params) {
    const indexInList = this.findLineInList(params)

    if (indexInList >= 0) {
      params.isFact ? this.factSchedule.splice(indexInList, 1) : this.planSchedule.splice(indexInList, 1)
    }
  }

  removePlanSchedule() {
    this.planSchedule = []
  }

  removeFactSchedule() {
    this.factSchedule = []
  }

  getPlanForAPI() {
    return this.planSchedule.map(el => el.getForAPI())
  }

  getFactForAPI() {
    return this.factSchedule.map(el => el.getForAPI())
  }

  buildFromAPI(lines) {
    if (lines.some(el => el.isFact)) {
      this.factSchedule = lines.map(el => ScheduleLine.buildFromAPI(el))
      this.sortFactLines()
    } else {
      this.planSchedule = lines.map(el => ScheduleLine.buildFromAPI(el))
      this.sortPlanLines()
    }

    return this
  }
}

export class ScheduleLine {
  constructor() {
    this.scheduleId = null       // 278239
    this.isFact = null           // факт/не факт
    this.date = null             // 2019-03-14

    this.startEndTimes = null    // 12:40-15:40
    this.name = null             // тема "Лучевые методы диагностики заболеваний"
    this.chapter = null          // Физиотерапия в акушерстве
    this._hours = null           // 4
    this.groups = []             // [1, 2]
    this.groupCount = null       // 3
    this.academicWork = null     // new AcademicWork()
    this.eventPlace = null       // new EventPlace()
    this.scheduleLects = []      // [] Employee()
  }

  get hours() {
    return this._hours
  }

  set hours(val) {
    this._hours = +val || null
  }

  parseDate(val) {
    const split = val.split(' ') // "2022-03-14 09:00"
    return split.length === 2 ? split[0] : null
  }

  parseStartEndTimes(from, to) {
    const fromSplit = from.split(' '), toSplit = to.split(' ')
    return (fromSplit.length === 2 && toSplit.length === 2) ? `${fromSplit[1]}-${toSplit[1]}` : null
  }

  joinDateWithTime() {
    const splitTime = this.startEndTimes?.split('-')
    return (splitTime?.length === 2) ? [`${this.date} ${splitTime[0]}`, `${this.date} ${splitTime[1]}`] : []
  }

  joinGroups() {
    return this.groups.length ? this.groups.join(', ') : null
  }

  splitGroups(groups) {
    return groups ? groups.split(', ') : []
  }

  getForAPI() {
    return {
      scheduleId: this.scheduleId,
      isFact: this.isFact,
      beginDateTime: this.joinDateWithTime()[0] || null,
      endDateTime: this.joinDateWithTime()[1] || null,
      name: this.name,
      chapter: this.chapter,
      hours: this.hours,
      groupCount: this.groups.length,
      groups: this.joinGroups(),
      academicWork: this.academicWork?.getForAPI() || null,
      eventPlace: this.eventPlace?.getForAPI() || null,
      scheduleLects: this.scheduleLects.map(el => el.getForAPI())
    }
  }

  static buildFromAPI(obj) {
    const _this = new ScheduleLine()

    if (obj) {
      _this.scheduleId = obj.scheduleId
      _this.isFact = obj.isFact
      _this.date = _this.parseDate(obj.beginDateTime)
      _this.startEndTimes = _this.parseStartEndTimes(obj.beginDateTime, obj.endDateTime)
      _this.name = obj.name
      _this.chapter = obj.chapter
      _this.hours = obj.hours
      _this.groupCount = obj.groupCount
      _this.groups = _this.splitGroups(obj.groups)
      _this.academicWork = obj.academicWork && AcademicWork.buildFromAPI(obj.academicWork)
      _this.eventPlace = obj.eventPlace && EventPlace.buildFromAPI(obj.eventPlace)
      _this.scheduleLects = obj.scheduleLects.map(el => Employee.buildFromAPI(el))
    }

    return _this
  }
}

export class ScheduleWidget {
  constructor() {
    this.planAcademicWorks = []
    this.factAcademicWorks = []
  }

  setAcademicWorksHours(list) {
    list.forEach(el => {
      if (el.hours) {
        const academicWork = el.academicWork

        academicWork.totalHours = el.hours
        academicWork.readyHours = 0
        academicWork.leftHours = el.hours

        el.isFact ? this.factAcademicWorks.push(academicWork) : this.planAcademicWorks.push(academicWork)
      }
    })
  }

  calculateWidget(scheduleLines) {
    console.log(scheduleLines)
    if (!scheduleLines.length) {
      return {
        isCorrect: true,
        academicWorks: []
      }
    }

    const isFactSchedule = scheduleLines.some(line => line.isFact)
    const percentDeviation = 15 // допустимое отклонение часов в %
    const academicWorks = isFactSchedule ? _cloneDeep(this.factAcademicWorks) : _cloneDeep(this.planAcademicWorks)
    const invalidAcademicWorks = [] // типы занятий, которые есть в строках расписания, но не запланированы
    let sumTotalHours = 0, sumReadyHours = 0

    scheduleLines.forEach(line => {
      const hours = line.hours ? +line.hours : 0
      const academicWork = academicWorks.find(item => item.id === line.academicWork?.id)

      if (academicWork) {
        academicWork.readyHours = academicWork.readyHours += hours
        academicWork.leftHours = academicWork.totalHours - academicWork.readyHours
      } else {
        const invalidAcademicWork = invalidAcademicWorks.find(item => item.id === line.academicWork?.id)

        if (invalidAcademicWork) {
          invalidAcademicWork.readyHours = invalidAcademicWork.readyHours += hours
          invalidAcademicWork.leftHours = invalidAcademicWork.totalHours - invalidAcademicWork.readyHours
        } else {
          const newInvalidAcademicWork = _cloneDeep(line.academicWork)
          if (newInvalidAcademicWork) {
            newInvalidAcademicWork.totalHours = 0
            newInvalidAcademicWork.readyHours = hours
            newInvalidAcademicWork.leftHours = newInvalidAcademicWork.totalHours - newInvalidAcademicWork.readyHours

            invalidAcademicWorks.push(newInvalidAcademicWork)
          }
        }
      }
    })

    academicWorks.forEach(el => {
      const hoursDeviation = el.totalHours / 100 * percentDeviation
      el.isCorrect = el.readyHours < el.totalHours + hoursDeviation && el.readyHours > el.totalHours - hoursDeviation

      sumTotalHours += el.totalHours
      sumReadyHours += el.readyHours
    })

    return {
      isCorrect: !invalidAcademicWorks.length && sumTotalHours === sumReadyHours,
      academicWorks: [...academicWorks, ...invalidAcademicWorks]
    }
  }

  getCalcWidget(planScheduleLines, factScheduleLines) {
    return {
      plan: this.calculateWidget(planScheduleLines),
      fact: this.calculateWidget(factScheduleLines)
    }
  }
}