import Vue from 'vue'
import VueRouter from 'vue-router'
import { START_LOCATION } from 'vue-router'
import { vm } from '@/main'
import store from '@/store'
import accessByRoles from '@/access'

VueRouter.prototype.openNewTab = url => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.rel = 'noopener noreferrer'
  setTimeout(() => link.click(), 150)
}

Vue.use(VueRouter)

const userAccess = async (to, from, next) => {
  if (['login', 'registration', 'registrationConfirm', 'forget', 'forgetConfirm'].includes(to.name)) {
    next()
    return
  }

  if (from === START_LOCATION) {
    await store.dispatch('user/getCurrent')
  }

  if (accessByRoles.checkAccessToRoute(to.name, store.getters['user/allUserRoles'])) {
    next()
  } else if (to.name !== 'department') {
    vm.$toastr('error', '', 'У Вас нет прав для просмотра данной страницы')
    await vm.$router.push('/department')
  } else {
    vm.$toastr('error', '', 'Обратитесь к администратору для получения прав доступа')
    await vm.$router.push('/login')
  }
}

class Page {
  constructor(path = null, component = null, name = null, props = false, beforeEnter = userAccess) {
    this.path = path
    this.name = name
    this.component = () => import(`@/pages/${component}`)
    this.props = props
    this.beforeEnter = beforeEnter
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: '/department'},
    new Page('/login', 'Auth/Login', 'login'),
    new Page('/registration/:login/:code', 'Auth/Registration', 'registrationConfirm'),
    new Page('/registration', 'Auth/Registration', 'registration'),
    new Page('/forget', 'Auth/ForgetPassword', 'forget'),
    new Page('/forget/:login/:code', 'Auth/ForgetPassword', 'forgetConfirm'),

    new Page('/department', 'Department/Department', 'department'),
    new Page('/department/event-places', 'Department/EventPlaces', 'eventPlaces'),
    new Page('/department/chapters', 'Department/Chapters', 'chapters'),
    new Page('/department/schedule-templates', 'Department/ScheduleTemplates', 'scheduleTemplates'),
    new Page('/department/schedule-template/:scheduleTemplateId', 'Department/ScheduleTemplate', 'scheduleTemplate'),
    new Page('/department/time-management', 'Department/TimeManagement', 'timeManagement'),
    new Page('/department/reports', 'Department/Reports', 'departmentReports'),

    new Page('/load', 'Load/Department', 'departmentLoad'),
    new Page('/load/cycles', 'Load/Cycles', 'cyclesLoad', true),

    new Page('/cycles', 'Cycles/index', 'cycles'),

    {
      ...new Page('/cycles/:cycleId', 'Cycles/Cycle/index', 'cycle'),
      redirect: { name: 'main' },
      children: [
        new Page('main', 'Cycles/Cycle/Main', 'main'),
        new Page('calendar', 'Cycles/Cycle/Calendar', 'calendar'),
        new Page('schedule', 'Cycles/Cycle/Schedule', 'schedule'),
        new Page('distribution', 'Cycles/Cycle/Distribution', 'distribution'),
        new Page('protocols', 'Cycles/Cycle/Protocols', 'protocols'),
        new Page('commission', 'Cycles/Cycle/Commission', 'commission'),

        {
          ...new Page('accounting', 'Cycles/Cycle/Accounting/index', 'accounting'),
          redirect: { name: 'accountingByListeners' },
          children: [
            new Page('by-listeners', 'Cycles/Cycle/Accounting/ByListeners', 'accountingByListeners'),
            new Page('by-themes', 'Cycles/Cycle/Accounting/ByThemes', 'accountingByThemes')
          ]
        }
      ]
    },

    new Page('/listeners', 'Listeners/List', 'listeners', true),
    new Page('/listeners/create', 'Listeners/Card', 'createListener'),
    new Page('/listeners/add', 'Listeners/Card', 'addListener', true),
    new Page('/listeners/edit/:id', 'Listeners/Card', 'editListener'),

    new Page('/employees', 'Employees/Employees', 'employees'),
    new Page('/employees/load', 'Employees/Load', 'employeesLoad'),

    new Page('/orders', 'Orders/index', 'orders'),

    {
      ...new Page('/management', 'Management/index', 'management'),
      // redirect: { name: 'holidays' },
      children: [
        new Page('holidays', 'Management/Holidays', 'holidays'),
        new Page('uploading-listeners', 'Management/UploadingListeners', 'uploadingListeners'),
        new Page('reports', 'Management/Reports', 'reports')
      ]
    },

    {
      ...new Page('/settings', 'Settings/index', 'settings'),
      redirect: { name: 'academicWorks' },
      children: [
        new Page('academic-works', 'Settings/AcademicWorks', 'academicWorks'),
        new Page('cycle-access', 'Settings/CycleAccess', 'cycleAccess'),
        new Page('head-teacher', 'Settings/HeadTeacher', 'headTeacher'),
        new Page('uploading-parameters', 'Settings/UploadingParameters', 'uploadingParameters')
      ]
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const listenersPages = ['listeners', 'createListener', 'addListener', 'editListener']

  // удаляем сохранённые в localStorage фильтры слушателя, когда выходим со страниц слушателей
  if (listenersPages.includes(from.name)) {
    !listenersPages.includes(to.name) && localStorage.removeItem('listenersFilters')
  }

  next()
})

export default router