import { repository, cancelToken, baseURL } from '@/api/base'
let cancel = null

export default {
  async getChairmans(cycleId) {
    const { data } = await repository.get(`${baseURL}/order/${cycleId}/getChairmans`)
    return data
  },

  async getSecretaries(cycleId) {
    const { data } = await repository.get(`${baseURL}/order/${cycleId}/getSecretaries`)
    return data
  },

  async getMembers(cycleId) {
    const { data } = await repository.get(`${baseURL}/order/${cycleId}/getMembers`)
    return data
  },

  async getCurrentYear() {
    const { data } = await repository.get(`${baseURL}/order/getCurrent`)
    return data
  },

  async getOrders() {
    const { data } = await repository.get(`${baseURL}/order/findAllWithoutLines`)
    return data
  },

  async getCommisionOrderLines(commissionOrderId, specialityId) {
    const { data } = await repository.get(`${baseURL}/order/${commissionOrderId}/${specialityId}/lines`)
    return data
  },

  async saveCommisionOrderLines(body) {
    const { data } = await repository.post(`${baseURL}/order/saveLines`, body)
    return data
  },

  async createOrder(body) {
    const { data } = await repository.post(`${baseURL}/order/createNew`, body)
    return data
  },

  async copyOrder(body) {
    const { data } = await repository.post(`${baseURL}/order/copy`, body)
    return data
  },

  async findEmployees(body) {
    cancel && cancel()

    const { data } = await repository.post(baseURL + `/order/emplByDeptPeriod`, body, {
      cancelToken: new cancelToken(c => cancel = c)
    })

    return data
  }
}