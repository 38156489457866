export const getDepartmentFromLocalStorage = userId => {
  const deptsByUser = JSON.parse(localStorage.getItem('departmentsByUser'))

  if (deptsByUser?.length) {
    const deptByUserId = deptsByUser.find(el => el.userId === userId)
    return deptByUserId?.departmentId
  }
}

export const setDepartmentToLocalStorage = body => {
  let deptsByUser = JSON.parse(localStorage.getItem('departmentsByUser'))

  if (deptsByUser?.length) {
    const deptIndex = deptsByUser.findIndex(el => el.userId === body.userId)
    deptIndex !== -1 ? deptsByUser.splice(deptIndex, 1, body) : deptsByUser.push(body)
  } else {
    deptsByUser = [body]
  }

  localStorage.setItem('departmentsByUser', JSON.stringify(deptsByUser))
}