const elementsWithDisabledAttribute = new Map()

export const setDisablePropertyForChildren = (el, tags, value, isBind) => {
  for (const tag of tags) {
    const elements = el.getElementsByTagName(tag)
    for (const element of elements)  {
      if (isBind && element.hasAttribute('disabled')) elementsWithDisabledAttribute.set(element, true)
      if (!elementsWithDisabledAttribute.get(element)) element.disabled = value
    }
  }
}