export class UploadingParameters {
  constructor() {
    this.list = []
  }

  addUploadingParameter(item) {
    this.list.push(UploadingParameter.buildFromAPI(item))
  }

  updateUploadingParameter(item) {
    const index = this.list.findIndex(el => el.id === item.id)
    this.list.splice(index, 1, UploadingParameter.buildFromAPI(item))
  }

  removeUploadingParameter(id) {
    const index = this.list.findIndex(el => el.id === id)
    this.list.splice(index, 1)
  }

  buildFromAPI(obj) {
    if (obj) {
      this.list = obj.map(el => UploadingParameter.buildFromAPI(el))
    }

    return this
  }
}


export class UploadingParameter {
  constructor() {
    this.id = null
    this.fromDuration = null
    this.toDuration = null
    this.fromListenerCount = null
    this.toListenerCount = null
    this.dayCount = null
  }

  getForAPI() {
    return {
      id: this.id,
      fromDuration: this.fromDuration,
      toDuration: this.toDuration,
      fromListenerCount: this.fromListenerCount,
      toListenerCount: this.toListenerCount,
      dayCount: this.dayCount
    }
  }

  static buildFromAPI(obj) {
    const _this = new UploadingParameter()

    if (obj) {
      _this.id = obj.id || null
      _this.fromDuration = obj.fromDuration || null
      _this.toDuration = obj.toDuration || null
      _this.fromListenerCount = obj.fromListenerCount || null
      _this.toListenerCount = obj.toListenerCount || null
      _this.dayCount = obj.dayCount || null
    }

    return _this
  }
}