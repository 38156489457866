export class Holidays {
  constructor() {
    this.list = []
  }

  get datesList() {
    return this.list.map(el => el.date)
  }

  sortByDate() {
    this.list.sort((a, b) => new Date(a.date) - new Date(b.date))
  }

  addHoliday(holiday) {
    this.list.push(Holiday.buildFromAPI(holiday))
    this.sortByDate()
  }

  removeHoliday(id) {
    const index = this.list.findIndex(el => el.id === id)
    index !== -1 && this.list.splice(index, 1)
  }

  buildFromAPI(list) {
    if (list) {
      this.list = list.map(el => Holiday.buildFromAPI(el))
      this.sortByDate()
    }

    return this
  }
}


export class Holiday {
  constructor() {
    this.id = null
    this.date = null
  }

  setDate(date) {
    this.date = date
  }

  getForAPI() {
    return {
      id: this.id,
      date: this.date
    }
  }

  static buildFromAPI(obj) {
    const _this = new Holiday()

    if (obj) {
      _this.id = obj.id || null
      _this.date = obj.date || null
    }

    return _this
  }
}