import { repository, baseURL } from '@/api/base'

export default {
  async getHeadTeacher(departmentId) {
    const {data} = await repository.get(`${baseURL}/headTeacher/byDepartmentId/${departmentId}`)
    return data
  },

  async setHeadTeacher(body) {
    const { data } = await repository.post(`${baseURL}/headTeacher`, body)
    return data
  }
}