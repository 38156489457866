export class Listeners {
  constructor() {
    this.list = []
  }

  addListener(obj) {
    !this.checkListenerInList(obj) && this.list.push(Listener.buildFromAPI(obj))
  }

  removeListener(id) {
    const index = this.list.findIndex(el => el.id === id)
    this.list.splice(index, 1)
  }

  checkListenerInList(obj) {
    return this.list.some(el => el.id === obj.id)
  }

  clearAll() {
    this.list = []
  }
}


class Listener {
  constructor() {
    this.id = null
    this.cycleId = null
    this.fullName = null
    this.state = null
    this.cycleNum = null
    this.snils = null
    this.status = null
  }

  static buildFromAPI(obj) {
    const _this = new Listener()

    if (obj) {
      _this.id = obj.id || null
      _this.cycleId = obj.cycleId || null
      _this.fullName = obj.fullName?.trim() || null
      _this.state = obj.state || null
      _this.cycleNum = obj.cycleNum || null
      _this.snils = obj.snils || null
      _this.status = obj.status
    }

    return _this
  }
}