const routes = {
  department: [
    'department', 'eventPlaces', 'scheduleTemplates',
    'scheduleTemplate', 'timeManagement', 'departmentReports','chapters'
  ],
  load: ['departmentLoad', 'cyclesLoad'],
  cycles: [
    'cycles', 'main', 'calendar', 'schedule', 'distribution', 'protocols',
    'commission', 'accountingByListeners', 'accountingByThemes'
  ],
  listeners: ['listeners', 'createListener', 'addListener', 'editListener'],
  employees: ['employees', 'employeesLoad'],
  orders: ['orders'],
  management: ['management', 'holidays', 'uploadingListeners', 'reports'],
  settings: ['settings', 'academicWorks', 'cycleAccess', 'headTeacher', 'uploadingParameters']
}


class AccessByRoles {
  constructor() {
    this.list = []
  }

  addRole(role) {
    this.list.push(role)
  }

  checkAccessToRoute(routeName, userRoles) {
    for (let i = 0; i <= userRoles.length; i++) {
      const role = this.list.find(el => el.name === userRoles[i])

      if (role && role.accessRouteNames.includes(routeName)) return true
    }

    return false
  }

  getRolesListByRouteName(routeName) {
    const rolesList = new Set()

    this.list.forEach(role => role.accessRouteNames.includes(routeName) && rolesList.add(role.name))

    return [...rolesList]
  }
}


class Role {
  constructor() {
    this.name = null
    this.accessRouteNames = []
  }

  static setName(role) {
    const _this = new Role()
    _this.name = role

    return _this
  }

  setAccess(routeGroups) {
    routeGroups.forEach(routeGroup => this.accessRouteNames.push(...routes[routeGroup]))

    return this
  }

  excludeRoutes(list) {
    list.forEach(route => {
      const index = this.accessRouteNames.indexOf(route)

      if (index > -1) {
        this.accessRouteNames.splice(index, 1)
      }
    })

    return this
  }
}


const accessByRoles = new AccessByRoles()

accessByRoles.addRole(
  Role
    .setName('ROLE_SUPERADMIN')
    .setAccess(['department', 'load', 'cycles', 'listeners', 'employees', 'orders', 'management', 'settings'])
)

accessByRoles.addRole(
  Role
    .setName('ROLE_ADMIN')
    .setAccess(['department', 'load', 'cycles', 'listeners', 'employees', 'orders', 'management', 'settings'])
)

accessByRoles.addRole(
  Role
    .setName('ROLE_AUDITOR')
    .setAccess(['department', 'load', 'cycles', 'listeners', 'employees', 'orders', 'management'])
    .excludeRoutes(['chapters'])

)

accessByRoles.addRole(
  Role
    .setName('ROLE_CURATOR')
    .setAccess(['department', 'load', 'cycles', 'listeners', 'employees', 'orders'])
)

accessByRoles.addRole(
  Role
    .setName('ROLE_DEAN')
    .setAccess(['department', 'management'])
    .excludeRoutes([
      'eventPlaces', 'scheduleTemplates', 'scheduleTemplate', 'timeManagement', 'departmentReports', 'chapters',
      'holidays', 'uploadingListeners'
    ])
)

accessByRoles.addRole(
  Role
    .setName('ROLE_DEPARTMENT_HEAD')
    .setAccess(['department', 'load', 'cycles', 'listeners', 'employees', 'orders'])
    .excludeRoutes(['chapters'])
)

accessByRoles.addRole(
  Role
    .setName('ROLE_EMPLOYEE')
    .setAccess(['department'])
    .excludeRoutes(['eventPlaces', 'scheduleTemplates', 'scheduleTemplate', 'timeManagement', 'departmentReports', 'chapters'])
)

accessByRoles.addRole(
  Role
    .setName('ROLE_HEAD_TEACHER')
    .setAccess(['department', 'load', 'cycles', 'listeners', 'employees', 'orders'])
)

accessByRoles.addRole(
  Role
    .setName('ROLE_TRAINING_MANAGER')
    .setAccess(['department', 'load', 'cycles', 'listeners', 'employees', 'orders', 'management'])
    .excludeRoutes(['chapters'])
)

export default accessByRoles