import { repository, baseURL } from "@/api/base"

export default {
  async getGradeTypes() {
    const { data } = await repository.get(`${baseURL}/grades/listenerGradeType`)
    return data
  },

  // получить оценки для голосования комиссии
  async getVotes(cycleId) {
    const { data } = await repository.get(`${baseURL}/grades/${cycleId}/getVotes`)
    return data
  },

  async setVote(body) {
    const { data } = await repository.post(`${baseURL}/grades/setVote`, body)
    return data
  },

  // За по умолчанию
  async setVoteForAll(cycleId) {
    const { data } = await repository.get(`${baseURL}/grades/${cycleId}/setVoteForAll`)
    return data
  },

  // получить оценки по слушателю
  async getGrades(fileDpoCardId) {
    const { data } = await repository.get(`${baseURL}/grades/${fileDpoCardId}/getGrades`)
    return data
  },

  // получить оценки по темам
  async getGradesByParams(body) {
    const { data } = await repository.post(`${baseURL}/grades/searchByParams`, body)
    return data
  },

  async saveGrade(body) {
    const { data } = await repository.post(`${baseURL}/grades/saveGrade`, body)
    return data
  },

  async updateGrade(body) {
    const { data } = await repository.put(`${baseURL}/grades/updateGrade`, body)
    return data
  }
}

