import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import Meta from 'vue-meta'
import VueToastr from '@deveodk/vue-toastr'
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'
import { setDisablePropertyForChildren } from "@/scripts";

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueTheMask)

Vue.use(Meta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueToastr, {
  defaultPosition: 'toast-bottom-left',
  defaultType: 'info',
  defaultTimeout: 5000,
})

Vue.prototype.showErrorMessage = (e, msg = 'Ошибка') => {
  console.log(e)
  const message = e?.status !== 500 ? e.data?.message : msg
  Vue.prototype.$toastr('error', `<b>Код ошибки: ${e.status}</b>`, message)
}

Vue.directive("disable-children", {
  bind: function(el, binding) {
    const tags = ["input", "textarea", "select"]
    setDisablePropertyForChildren(el, tags, binding.value, true)
  },

  update: function(el, binding) {
    if (binding.value === binding.oldValue) return
    const tags = ["input", "textarea", "select"]
    setDisablePropertyForChildren(el, tags, binding.value, false)
  },
});

export const eventBus = new Vue()

export const vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')