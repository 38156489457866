import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.min.css'

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

export default new Vuetify({
	icons: {
    iconfont: 'mdi',
	},

	theme: {
    themes: {
      light: {
        background: '#f6f7fb',
        primary: '#2196F3',
				success: '#61B329',
				warning: '#FF6F00',
				error: '#FF5252',
        tabError: '#EF5350',
				accent: '#37474F',
				lightGrey: '#40494e', // по хорошему в иконках заменить на icon
				btnIcon: '#40494e',
        disabled: '#c4c4c4',
        separator: '#e0e0e0',
        selectedRow: '#E8E8E8'
      }
    }
  }
})