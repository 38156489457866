export class Grades {
  constructor() {
    this.list = []
  }

  setThemeParams(gradeList) {
    this.list.forEach(el => {
      el.name = el.chapterTemplateLine?.chapter?.name || el.examType?.name
      el.gradeList = gradeList
    })
  }

  setListenerParams(gradeList) {
    this.list.forEach(el => {
      el.name = el.fileDpoCard?.fullName
      el.gradeList = gradeList
    })
  }

  buildFromAPI(list) {
    if (list) {
      this.list = list.map(el => Grade.buildFromAPI(el))
    }

    return this
  }
}


class Grade {
  constructor() {
    this.id = null
    this.chapterTemplateLine = null
    this.fileDpoCard = null
    this.gradeType = null
    this.gradeTitle = null
    this.theme = null
    this.examType = null

    this.name = null
    this.gradeList = []
  }

  setId(id) {
    this.id = id
  }

  setGrade(grade) {
    this.gradeTitle = grade
  }

  getForAPI() {
    return {
      id: this.id,
      chapterTemplateLine: this.chapterTemplateLine,
      fileDpoCard: this.fileDpoCard,
      gradeType: this.gradeType,
      gradeTitle: this.gradeTitle,
      theme: this.theme,
      examType: this.examType
    }
  }

  static buildFromAPI(obj) {
    const _this = new Grade()

    if (obj) {
      _this.id = obj.id || null
      _this.chapterTemplateLine = obj.chapterTemplateLine || null
      _this.fileDpoCard = obj.fileDpoCard || null
      _this.gradeType = obj.gradeType || null
      _this.gradeTitle = obj.gradeTitle || null
      _this.theme = obj.theme || null
      _this.examType = obj.examType || null
    }

    return _this
  }
}