<template>
  <v-app :style="[cssVars]" class="app-background-color">
    <LeftMenu
      v-if="!isSmAndDown && computedLinks.length"
      :links="computedLinks"
    />

    <Navbar
      :links="computedLinks"
      :isSmAndDown="isSmAndDown"
      :isXs="isXs"
      @openInstructionsDialog="instructionsDialog = true"
    />

    <v-main class="background main">
      <InstructionsDialog
        :dialog="instructionsDialog"
        @closeInstructionsDialog="instructionsDialog = false"
      />

      <router-view :isSmAndDown="isSmAndDown" />
    </v-main>

    <v-footer padless class="background footer">
      <v-col class="caption accent--text px-4" cols="12">
        © {{ new Date().getFullYear() }}, Дополнительное профессиональное образование
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import accessByRoles from '@/access'
import { mapGetters } from 'vuex'
import Navbar from '@/components/ui/Navbar'
import LeftMenu from '@/components/ui/LeftMenu'
import InstructionsDialog from "@/components/dialogs/Instructions"

export default {
  name: 'App',

  components: {
    Navbar,
    LeftMenu,
    InstructionsDialog
  },

  created() {
    // this.checkUserMessageFlag()
  },

  mounted() {
    this.$store.dispatch('breakpoint/addWindowWidthEventListener')
  },

  destroyed() {
    this.$store.dispatch('breakpoint/removeWindowWidthEventListener')
  },

  data: () => ({
    instructionsDialog: false,

    links: [
      {
        icon: "mdi-bank-outline",
        text: "Кафедра",
        route: "/department",
        routeName: "department"
      },
      {
        icon: "mdi-calendar-month-outline",
        text: "Нагрузка",
        route: "/load",
        routeName: "departmentLoad"
      },
      {
        icon: "mdi-calendar-clock-outline",
        text: "Циклы",
        route: "/cycles",
        routeName: "cycles"
      },
      {
        icon: "mdi-account-group-outline",
        text: "Слушатели",
        route: "/listeners",
        routeName: "listeners"
      },
      {
        icon: "mdi-account-multiple-outline",
        text: "Сотрудники",
        route: "/employees",
        routeName: "employees"
      },
      {
        icon: "mdi-file-document-multiple-outline",
        text: "Приказы",
        route: "/orders",
        routeName: "orders"
      },
      {
        icon: "mdi-account-cog-outline",
        text: "Учебное управление",
        route: "/management",
        routeName: "management"
      },
      {
        icon: "mdi-cog-outline",
        text: "Настройки",
        route: "/settings",
        routeName: "settings"
      }
    ]
  }),

  computed: {
    ...mapGetters('breakpoint', ['isSmAndDown', 'isXs']),
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('user', ['userId', 'allUserRoles', 'isOperatorRoles']),
    ...mapGetters('department', ['department']),

    computedLinks() {
      if (!this.userId) return []

      if (this.department) {
        return this.links.filter(link => {
          const rolesList = accessByRoles.getRolesListByRouteName(link.routeName)

          return rolesList.some(role => this.allUserRoles.includes(role))
        })
      } else {
        return this.isOperatorRoles ? [this.links[0]] : []
      }
    },

    cssVars() {
      const vuetifyColors = {}

      // создадим html переменные цветов из констант Vuetify
      Object.keys(this.$vuetify.theme.themes.light).forEach(key => {
        vuetifyColors[`--${key}-color`] = this.$vuetify.theme.themes.light[key]
      })

      return {
        '--tech-message-height': this.techMessageHeight + 'px',
        ...vuetifyColors
      }
    }
  }
}
</script>

<style>
@import '~@/assets/styles/main.css';
@import '~@/assets/styles/table.css';
@import '~@/assets/styles/card.css';
@import '~@/assets/styles/dialog.css';
@import '~@/assets/styles/menu.css';
@import '~@/assets/styles/tabs.css';
@import '~@/assets/styles/filter.css';
@import '~@/assets/styles/buttons.css';
@import '~@/assets/styles/input.css';
@import '~@/assets/styles/alert.css';

html {
  overflow-y: auto !important;
}

body {
  color: #37474f;
}

.app-background-color {
  background-color: var(--background-color) !important;
}

.custom-tabs-arrows .v-slide-group__prev {
  min-width: 28px !important;
  justify-content: left !important;
  flex: 0 1 32px !important;
}

.custom-tabs-arrows .v-slide-group__next {
  min-width: 28px !important;
  justify-content: left !important;
  flex: 0 1 32px !important;
}

.custom-tabs-arrows .v-slide-group__next i {
  margin-left: 6px;
}

.footer {
  z-index: 1;
}

.main {
  margin-top: calc(100px + var(--tech-message-height))
}

.toast.toast-warning {
  background-color: #FF6F00 !important;
}

.toast.toast-warning .toast-title {
  color: #FF6F00 !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #B0B0B0;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.required label:after, .required-span:after {
  color: red;
  content: " *";
  font-weight: bold;
  font-size: 16px;
}
</style>